const MMMM = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
const dd = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

class DateHelper {
    constructor() {
        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);
    }

    dateToPixel(date, nowPosition, dayWidth) {
        // Math.round is due to daylight saving time
        const daysNum = Math.round( (date - this.today) / 1000 / 60 / 60 / 24 );
        return nowPosition + daysNum * dayWidth;
    }

    pixelToDate(position, nowPosition, dayWidth) {
        const days = Math.round((position - nowPosition) / dayWidth);
        const msFromNow = this.today.getTime() + days * 24 * 60 * 60 * 1000;
        return new Date(msFromNow);
    }

    daysDiff(start, end = this.today) {
        return Math.round( (start - end) / 1000 / 60 / 60 / 24 );
    }

    daysInMonth(month, year) {
        return new Date(year, month + 1, 0).getDate();
    }

    daysInYear(year) {
        return /* is leap year? 366 : 365 */ year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0) ? 366 : 365;
    };

    format(date, fmtStr) {
        return fmtStr.split(' ').map(str => this.getFormatPart(date, str)).join(' ');
    }

    getFormatPart(date, str) {
        if (str === 'YYYY')
            return date.getFullYear().toString();
        else if (str === 'MMMM')
            return MMMM[date.getMonth()];
        else if (str === 'dd')
            return dd[date.getDay()];
        else if (str === 'D')
            return date.getDate().toString();
        else if (str === 'WW')
            return ('0' + this.getWeekNumber(date)).slice(-2);
    }

    // https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
    getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        return /* let weekNo =*/ Math.ceil(( ( (d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        // return [d.getUTCFullYear(), weekNo];
    }

    getStartDate(date, mode) {
        switch (mode) {
            case 'year':
                return new Date(date.getFullYear(), 0, 1);
            case 'month':
                return new Date(date.getFullYear(), date.getMonth(), 1);
            case 'week':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - (date.getDay() || 7));
            default:
                return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
    }

    getIncrement(date, mode) {
        switch (mode) {
            case 'year':
                return this.daysInYear(date.getFullYear());
            case 'month':
                return this.daysInMonth(date.getMonth(), date.getFullYear());
            case 'week':
                return 7;
            default:
                return 1;
        }
    }
}

export default new DateHelper();