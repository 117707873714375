import React from "react";
import {NavLink, useLocation} from 'react-router-dom';
import {Button, Divider, Navbar, NavbarSection} from "spectre-react";
import {useCurrentUserValue} from "../contexts/currentUser";


const AppNavbar = () => {
    const currentUser = useCurrentUserValue();
    const location = useLocation();

    if (!currentUser)
        return null;

    return (
        <div id="navbar">
            <Navbar className="mb-2">
                <NavbarSection className="hide-sm">
                    <img src="/logo.svg" className="img-responsive" alt="Логотип" />
                </NavbarSection>

                <NavbarSection>
                    <Button link renderAs={NavLink} exact to="/requests/absence">Заявки</Button>
                    <Button link renderAs={NavLink} to="/requests/cto">Регистрация отгулов</Button>
                    <Button link renderAs={NavLink} exact to="/diagram">Диаграмма</Button>
                    <Button link renderAs={NavLink} exact to="/events">События</Button>
                    <Button link renderAs={NavLink} exact to={{pathname: "/sign_out", state: { from: location }}}>
                        Выйти
                    </Button>
                </NavbarSection>
            </Navbar>

            <Divider id="divider"/>
        </div>
    )
}


export default AppNavbar;