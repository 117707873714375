import React, {useEffect} from "react";
import {REQUEST_KIND} from "../../../shared";
import AbsenceRequestsList from "./AbsenceRequestsList";
import Settings from "./Settings";
import RequestsPageColumns from "../shared/requestsPageColumns";
import useSettingsObject from "../shared/useSettingsObject";
import useFetch from "../../../hooks/useFetch";
import {Link} from "react-router-dom";
import {Loading, Icon, Toast} from "spectre-react";


const defaultSettings = {
    requestKinds: Object.fromEntries(Object.keys(REQUEST_KIND).map(kind => [kind, false])),
    onlyPending: false,
    groupByYear: false,
}


const NoRequestsMessage = () => {
    return (
        <div className="empty">
            <div className="empty-icon">
                <Icon icon="emoji" size="3x"/>
            </div>
            <p className="empty-title h5">У вас нет ни одной заявки</p>
            <div className="empty-action">
                <Link className="btn btn-primary" to="/requests/new">Создать заявку</Link>
            </div>
        </div>
    );
}


const MyAbsenceRequestsList = () => {
    useEffect(() => { document.title = "Мои заявки | Vacation" }, []);

    const [settings, onChange] = useSettingsObject(defaultSettings);

    const [{isLoading, response: requests, error}, doFetch] = useFetch(`/api/requests/absence/currentUser`);

    useEffect(doFetch, [doFetch]);

    if (error)
        return <Toast error>{error.message}</Toast>;

    if (isLoading || !requests)
        return <Loading large />;

    if (requests.length === 0)
        return <NoRequestsMessage/>;

    return (
        <RequestsPageColumns>
            <AbsenceRequestsList requests={requests} settings={settings} onResponseSubmitted={doFetch} />
            <Settings settings={settings} onChange={onChange} />
        </RequestsPageColumns>
    )
}

export default MyAbsenceRequestsList;