import React from "react";
import {Checkbox, FormGroup} from "spectre-react";


const VerticalLinesSwitcher = React.memo(({checked, setChecked}) => {
    return (
        <FormGroup>
            <Checkbox switch checked={checked} onChange={ev => setChecked(ev.target.checked)} className="my-0">
                Вертикальные линии
            </Checkbox>
        </FormGroup>
    )
});


export default VerticalLinesSwitcher;