import {pluralize} from "../../shared";
import {Icon} from "spectre-react";


const ReviewsRequiredList = ({count, children}) => {
    const word = pluralize(count, undefined, 'заявку', 'заявки', 'заявок');

    return (
        <div className="empty">
            <div className="empty-icon">
                <Icon icon="mail" size="3x"/>
            </div>
            <p className="empty-title h5">Необходимо ответить на {count} {word}.</p>
            <div className="text-left">
                {children}
            </div>
        </div>
    )
};


export default ReviewsRequiredList;