import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch, NavLink} from 'react-router-dom';
import NewEventPage from "./NewEventPage";
import EditEventPage from "./EditEventPage";
import EventsTable from "./EventsTable";


const URL_PATH = {
    TABLE: '/events',
    NEW: '/events/new',
    EDIT: '/events/:authorId/:eventId/edit',
};


const Tabs = () => {
    return (
        <div className="text-center">
            <div className="tabs btn-group">
                <NavLink exact className="btn btn-sm" to={URL_PATH.TABLE}>Список событий</NavLink>
                <NavLink exact className="btn btn-sm" to={URL_PATH.NEW}>Добавить событие</NavLink>
            </div>
        </div>
    );
};


const EventsPage = () => {
    useEffect(() => {document.title = "События | Vacation"}, []);

    return (
        <Router>
            <Tabs />

            <Switch>
                <Route exact path={URL_PATH.TABLE} component={EventsTable} />
                <Route exact path={URL_PATH.NEW} component={NewEventPage} />
                <Route exact path={URL_PATH.EDIT} component={EditEventPage} />
            </Switch>
        </Router>
    )
};


export default EventsPage;