const RequestsPageColumns = ({children}) => {
    const list = children[0];
    const settings = children[1];

    return (
        <div className="requests-list absence-requests-list columns">
            <div className="column col-sm-12 col-md-7 col-9">
                {list}
            </div>

            <div className="column col-sm-12 col-md-5 col-3">
                {settings}
            </div>
        </div>
    )
};


export default RequestsPageColumns;