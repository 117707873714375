import React, {useEffect} from "react";
import {REQUEST_KIND} from "../../../shared";
import AbsenceRequestsTable from "./AbsenceRequestsTable";
import Settings from "./Settings";
import RequestsPageColumns from "../shared/requestsPageColumns";
import useFetch from "../../../hooks/useFetch";
import useSettingsObject from "../shared/useSettingsObject";
import {Loading, Toast} from "spectre-react";


const defaultSettings = {
    requestKinds: {
        [REQUEST_KIND.VACATION_PAID]: true,
        [REQUEST_KIND.VACATION_UNPAID]: true
    },
    year: new Date().getFullYear().toString(),
    month: ('0' + (new Date().getMonth() + 1)).slice(-2),
    onlyApproved: true,
};


function getYearOptions(requests) {
    const years = [];

    const minYear = parseInt(requests[0].startDate.slice(0, 4), 10);
    const maxYear = parseInt(requests[requests.length - 1].startDate.slice(0, 4), 10);

    for (let year = minYear; year <= maxYear; ++year)
        years.push(year.toString());

    return years;
}


const AllAbsenceRequestsList = ({currentUser}) => {
    useEffect(() => { document.title = "Все заявки | Vacation" }, []);

    // -------

    const [{isLoading, response: requests, error}, doFetch] = useFetch(`/api/requests/absence/all`);

    useEffect(doFetch, [doFetch]);

    // -------

    const [settings, onChange] = useSettingsObject(defaultSettings);

    // -------

    if (error)
        return <Toast error>{error.message}</Toast>;

    if (isLoading || !requests)
        return <Loading large />;

    // -------

    const years = getYearOptions(requests);

    return (
        <RequestsPageColumns>
            <AbsenceRequestsTable requests={requests} settings={settings} currentUser={currentUser}/>
            <Settings years={years} settings={settings} onChange={onChange}/>
        </RequestsPageColumns>
    )
}

export default AllAbsenceRequestsList;