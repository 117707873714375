import React, {useEffect, useContext, useCallback, useState} from "react";
import useFetch from "../../../hooks/useFetch";
import ResponseSubmittedContext from "../../../contexts/responseSubmitted";
import {RESPOND_ACTION, REQUEST_KIND} from "../../../shared";
import {Button, FormGroup} from "spectre-react";


const useResponseApi = ({request, action, successMessage}) => {
    const url = `/api/requests/absence/${request.authorId}/${request._id}/responses`;
    const [fetchState, doFetch] = useFetch(url);

    const submitCallback = useContext(ResponseSubmittedContext);

    const doSubmitResponse = useCallback(({comment}) => {
        doFetch({ method: 'post', data: {comment, action} });
    }, [doFetch, action]);

    useEffect(() => {
        if (fetchState.response) {
            alert(successMessage);
            submitCallback();
        }

        if (fetchState.error)
            alert(`Ошибка: ${fetchState.error.message}`);
    }, [fetchState.response, fetchState.error, submitCallback]);

    return [fetchState, doSubmitResponse];
};


const CancelForm = ({request}) => {
    const [comment, setComment] = useState('');

    const successMessage = "Заявка отменена.";
    const action = RESPOND_ACTION.CANCEL;
    const [{isLoading}, doCancel] = useResponseApi({request, action, successMessage})

    return (
        <form className="mt-6">
            <FormGroup>
                <textarea name="comment" className="form-input"
                          placeholder="По желанию можно указать комментарий."
                          value={comment} onChange={ev => setComment(ev.target.value)}
                />
            </FormGroup>

            <FormGroup>
                <Button primary loading={isLoading} onClick={() => doCancel({comment})}>
                    Отменить
                </Button>
            </FormGroup>
        </form>
    );
};


const RespondForm = ({request}) => {
    const [approveFetchState, doApprove] = useResponseApi({
        request,
        action: RESPOND_ACTION.APPROVE,
        successMessage: "Заявка одобрена."
    });

    const [rejectFetchState, doReject] = useResponseApi({
        request,
        action: RESPOND_ACTION.REJECT,
        successMessage: "Заявка отклонена."
    });

    const [comment, setComment] = useState('');

    const isLoading = approveFetchState.isLoading || rejectFetchState.isLoading;

    return (
        <form className="mt-6">
            {request.kind === REQUEST_KIND.DAYS_OFF &&
                <FormGroup>
                    Доступно отгулов: {request.ctoDaysAvailable} д.
                </FormGroup>
            }

            <FormGroup>
                <textarea name="comment" className="form-input"
                          placeholder="Если вы отклоняете заявку, то необходимо указать комментарий.&#10;В случае одобрения — по желанию."
                          value={comment} onChange={ev => setComment(ev.target.value)}
                />
            </FormGroup>

            <FormGroup>
                <Button success className="mr-2" loading={isLoading} onClick={() => doApprove({comment})}>
                    Одобрить
                </Button>
                или
                <Button error className="ml-2" loading={isLoading} onClick={() => doReject({comment})}>
                    Отклонить
                </Button>
            </FormGroup>
        </form>
    )
};

export {CancelForm, RespondForm};