import Request from "../../shared/request";
import RequestLabel from "./RequestLabel";
import RequestTimeline from "../shared/CTORequestTimeline";
import {MyRequestsCTORequestsPT} from "../../propTypes";


const CTORequestsList = ({requests}) => {
    return (
        <div className="requests-list">
            {requests.map(request =>
                <Request key={request._id}
                         requestType='cto'
                         request={request}
                         labelComponent={RequestLabel}
                         timelineComponent={RequestTimeline}
                />
            )}
        </div>
    )
};


CTORequestsList.propTypes = { requests: MyRequestsCTORequestsPT }


export default CTORequestsList;