import React from "react";
import ResponseSubmittedContext from "../../../contexts/responseSubmitted";
import ReviewsRequiredList from "../../shared/ReviewsRequiredList";
import RequestsGroup from "../../shared/RequestsGroup";
import Request from "../../shared/request";
import RequestLabel from "./RequestLabel";
import RequestTimeline from "../shared/absenceRequestTimeline";
import {REQUEST_STATUS} from '../../../shared';
import {groupBy} from "../shared";
import {SubordinatesRequestsAbsenceRequestsPT} from "../../propTypes";


function filterRequests(requests, settings) {
    const {requestKinds, onlyApproved, onlyPending} = settings;

    if (Object.values(requestKinds).some(value => value))
        requests = requests.filter(request => requestKinds[request.kind]);

    if (onlyApproved)
        requests = requests.filter(request => request.status === REQUEST_STATUS.APPROVED);

    if (onlyPending)
        requests = requests.filter(request => request.status === REQUEST_STATUS.PENDING);

    return requests;
}


function buildGroups(requests, settings) {
    requests = filterRequests(requests, settings);

    const keyFunc = settings.groupByUser
        ? (request) => request.authorFullName
        : (request) => request.calendarYear;

    const keyToTitle = (something) => something.toString();

    const sortFunc = settings.groupByUser
        ? (a, b) => a[0].localeCompare(b[0])
        : (a, b) => b[0].localeCompare(a[0]);

    return groupBy(requests, keyFunc)
        .map(([key, requests]) => ([keyToTitle(key), requests]))
        .sort(sortFunc);
}


const AbsenceRequestsList = ({requests, settings, currentUser, onResponseSubmitted}) => {
    const reviewableRequests = requests.filter(({nextReviewerId}) => nextReviewerId === currentUser._id);

    const groups = buildGroups(requests, settings);

    return (
        <ResponseSubmittedContext.Provider value={onResponseSubmitted}>
            {reviewableRequests.length > 0 &&
                <ReviewsRequiredList count={reviewableRequests.length}>
                    {reviewableRequests.map(request =>
                        <Request key={request._id}
                                 requestType='absence'
                                 request={request}
                                 labelComponent={RequestLabel}
                                 timelineComponent={RequestTimeline}
                        />
                    )}
                </ReviewsRequiredList>
            }

            {groups.map(
                ([title, requests]) =>
                    <RequestsGroup key={title} title={title} requests={requests} open={false}>
                        {requests.map(request =>
                            <Request key={request._id}
                                     requestType='absence'
                                     request={request}
                                     labelComponent={RequestLabel}
                                     timelineComponent={RequestTimeline}
                            />
                        )}
                    </RequestsGroup>
            )}
        </ResponseSubmittedContext.Provider>
    )
}


AbsenceRequestsList.propTypes = { requests: SubordinatesRequestsAbsenceRequestsPT };


export default AbsenceRequestsList;