import React, {useRef, useEffect, useReducer} from "react";
import useDrag from "./useDrag";

import VerticalSplitter from "./components/VerticalSplitter";
import Header from "./components/Header";
import DataViewport from "./components/DataViewPort";
import TaskList from "./components/TaskList";
import Control from "./components/Control";

import DateHelper from './helpers/DateHelper';

import {DATA_CONTAINER_WIDTH, SIDE_ROW_MIN_WIDTH, HORIZON, SCROLL_BACKWARD} from "./helpers/Const";
import {VIEW_MODE_MONTH, DAY_WIDTH_MONTH_MODE, DAY_WIDTH_YEAR_MODE} from "./helpers/Const";

import "./Timeline.scss";

const reducer = (state, action) => {
    switch (action.type) {
        case 'ADVANCE': {
            const daysNum = (state.mode === VIEW_MODE_MONTH ? 10 : 21);
            const delta = (action.payload === SCROLL_BACKWARD ? -1 : 1) * daysNum * state.dayWidth;
            const dragAction = {type: 'DRAG_VIEWPORT', payload: delta};
            return reducer(state, dragAction);
        }

        case 'DRAG_VIEWPORT': {
            const delta = action.payload;
            if (delta === 0)
                return state;

            let scrollLeft = state.scrollLeft + delta;
            let {nowPosition, pxToScroll} = state;
            let currentDay = Math.trunc((scrollLeft - nowPosition) / state.dayWidth);

            if (scrollLeft > pxToScroll) {
                nowPosition = nowPosition - pxToScroll;
                scrollLeft = 0;
            } else if (scrollLeft <= 0) {
                nowPosition = nowPosition + pxToScroll;
                scrollLeft = pxToScroll;
            }

            return {...state, currentDay, nowPosition, scrollLeft};
        }

        case 'CHANGE_SCALE': {
            const mode = action.payload; // action.payload is either VIEW_MODE_MONTH or VIEW_MODE_YEAR
            const {currentDay, vpWidth, pxToScroll} = state;

            const dayWidth = mode === VIEW_MODE_MONTH ? DAY_WIDTH_MONTH_MODE : DAY_WIDTH_YEAR_MODE;
            const numVisibleDays = Math.ceil(vpWidth / dayWidth);

            const scrollTimes = Math.ceil((-currentDay * dayWidth) / pxToScroll);
            const nowPosition = scrollTimes * pxToScroll;
            const scrollLeft = (currentDay * dayWidth + nowPosition) % pxToScroll;

            return {...state, mode, dayWidth, numVisibleDays, nowPosition, scrollLeft};
        }
    
        case 'CHANGE_VIEWPORT_SIZE': {
            // this action is triggered after first render (somewhat twice) and on every window resize
            if (state.vpWidth === action.payload)
                return state;
            const vpWidth = action.payload;
            const pxToScroll = Math.floor((1 - vpWidth / DATA_CONTAINER_WIDTH) * DATA_CONTAINER_WIDTH);
            const numVisibleDays = Math.ceil(vpWidth / state.dayWidth);
            return {...state, vpWidth, pxToScroll, numVisibleDays};
        }

        case 'DRAG_SPLITTER': {
            const delta = action.payload;
            const sideWidth = Math.max(state.sideWidth - delta, SIDE_ROW_MIN_WIDTH);
            return {...state, sideWidth};
        }

        default:
            return state;
    }
};

const initialState = {
    pxToScroll: DATA_CONTAINER_WIDTH, // the number of pixels the viewport can scroll till arrive to the end of the context
    currentDay: 0, // day that is in the 0px horizontal
    nowPosition: 0, // zero reference position
    sideWidth: 200,
    scrollLeft: 0,
    numVisibleDays: 60,
    mode: VIEW_MODE_MONTH,
    dayWidth: DAY_WIDTH_MONTH_MODE,
    vpWidth: 1, // vp is viewport
};

const Timeline = ({scale, showLines, requests, events, onHorizonChange}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // -----

    useEffect(() => {
        if (scale && scale !== state.mode)
            dispatch({type: 'CHANGE_SCALE', payload: scale});
    }, [scale, state.mode]);

    // -----

    const limits = useRef({left: 1, right: -1});
    
    useEffect(() => {
        if (!onHorizonChange) return;
        // left border of viewport is lower than left limit or right border of viewport is higher than right limit
        if (state.scrollLeft < limits.current.left || limits.current.right < (state.scrollLeft + state.vpWidth)) {
            const {scrollLeft, vpWidth, nowPosition, dayWidth} = state;
            limits.current = {
                left: scrollLeft /* left border of viewport */ - HORIZON,
                right: (scrollLeft + vpWidth) /* right border of viewport */ + HORIZON
            };
            onHorizonChange({
                start: DateHelper.pixelToDate(limits.current.left, nowPosition, dayWidth),
                end: DateHelper.pixelToDate(limits.current.right, nowPosition, dayWidth)
            });
        }
    });

    // -----

    const dragCallbacks = useDrag(dispatch);

    const viewportProps = {
        scrollLeft: state.scrollLeft,
        nowPosition: state.nowPosition,
        dayWidth: state.dayWidth,
        dragCallbacks,
        currentDay: state.currentDay,
        numVisibleDays: state.numVisibleDays,
        mode: state.mode,
        showLines
    };

    const {user, subordinates, other} = requests;

    return (
        <div className="timeLine">
            <div className="timeLine-section-content">
                <Control width={state.sideWidth} dispatch={dispatch}/>
                <VerticalSplitter dispatch={dispatch}/>
                <Header
                    nowPosition={state.nowPosition}
                    currentDay={state.currentDay}
                    numVisibleDays={state.numVisibleDays}
                    dayWidth={state.dayWidth}
                    mode={state.mode}
                    scrollLeft={state.scrollLeft}
                    dragCallbacks={dragCallbacks}
                    dispatch={dispatch} // react-sizeme
                />
            </div>

            {user.length > 0 && 
                <div className="timeLine-section">
                    <div className="timeLine-section-title">Мои заявки</div>
                    <div className="timeLine-section-content">
                        <TaskList rows={user} width={state.sideWidth}/>
                        <VerticalSplitter dispatch={dispatch}/>
                        <DataViewport rows={user} {...viewportProps}/>
                    </div>
                </div>}

            {subordinates.length > 0 &&
                <div className="timeLine-section">
                    <div className="timeLine-section-title">Завяки моих подчиненных</div>
                    <div className="timeLine-section-content">
                        <TaskList rows={subordinates} width={state.sideWidth}/>
                        <VerticalSplitter dispatch={dispatch}/>
                        <DataViewport rows={subordinates} {...viewportProps}/>
                    </div>
                </div>}

            {other.length > 0 &&
                <div className="timeLine-section">
                    <div className="timeLine-section-title">Завяки других сотрудников</div>
                    <div className="timeLine-section-content">
                        <TaskList rows={other} width={state.sideWidth}/>
                        <VerticalSplitter dispatch={dispatch}/>
                        <DataViewport rows={other} {...viewportProps}/>
                    </div>
                </div>}

            <div className="timeLine-section">
                <div className="timeLine-section-title">
                    {events.length > 0 ? "События" : "В данном промежутке нет событий"}
                </div>
                <div className="timeLine-section-content">
                    <TaskList rows={events} width={state.sideWidth}/>
                    <VerticalSplitter dispatch={dispatch}/>
                    <DataViewport rows={events} {...viewportProps}/>
                </div>
            </div>
        </div>
    );
};


export default Timeline;
