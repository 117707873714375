import React from "react";
import BaseRequestLabel from "../../shared/request/Label";
import {humanPeriodString, pluralize, REQUEST_KIND_STR} from "../../../shared";
import {Col, Label, Row} from "spectre-react";


const RequestLabel = ({request, onClick}) => {
    const {startDate, endDate, daysCount} = request;
    const datesString = humanPeriodString(startDate, endDate);
    const daysCountString = `${daysCount} ${pluralize(daysCount, "дней", "день", "дня", "дней")}`;

    return (
        <BaseRequestLabel request={request} onClick={onClick} className="label-absence-my">
            <Row renderAs='span' gapless>
                <Col renderAs='span' all={8} md={12}>
                    {datesString} ({daysCountString})
                </Col>
                <Col renderAs='span' all={4} md={12} className="text-right text-left-md">
                    <Label rounded>
                        {REQUEST_KIND_STR[request.kind]}
                    </Label>
                </Col>
            </Row>
        </BaseRequestLabel>
    )
};


export default RequestLabel;