import React from "react";
import {Accordion, AccordionBody, AccordionHeader, Icon} from "spectre-react";


const RequestsGroup = ({title, children, open = false}) => {
    return (
        <Accordion open={open}>
            <AccordionHeader className="text-ellipsis h2 mb-2">
                <Icon icon="arrow-right" className="mr-1"/>
                <span>{title}</span>
            </AccordionHeader>
            <AccordionBody>
                {children}
            </AccordionBody>
        </Accordion>
    )
}


export default RequestsGroup;