import React, {useState, useLayoutEffect, useCallback, useRef} from 'react';

const VerticalSplitter = React.memo(({dispatch}) => {
    const [isDragging, setIsDragging] = useState(false);
    const draggingPosition = useRef(0);

    const onMouseDown = useCallback(ev => {
        if (ev.button === 0) { // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
            draggingPosition.current = ev.clientX;
            setIsDragging(true);
        }
    }, []);

    const onMouseMove = useCallback(ev => {
        ev.stopPropagation();
        const delta = draggingPosition.current - ev.clientX;
        draggingPosition.current = ev.clientX;
        dispatch({type: 'DRAG_SPLITTER', payload: delta});
    }, [dispatch]);

    const onMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    useLayoutEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        } else {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }
    }, [isDragging, onMouseMove, onMouseUp]);

    return <div className="timeLine-resizer" onMouseDown={onMouseDown}/>;
});

export default VerticalSplitter;