import React from "react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {humanDate, parseHumanDate, formatDate1, isMobile} from "../shared";

const dateLocale = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdaysLong: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота',],
    weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
    firstDayOfWeek: 1,
    locale: 'ru',
    labels: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' }
};


const DateInput = React.memo(({name, label, value, onChange, min}) => {
    if (isMobile())
        return (
            <input type="date" className="form-input" id={name} name={name} value={value} onChange={onChange} min={min}/>
        );

    const dayPickerProps = {
        ...dateLocale,
        disabledDays: {before: new Date(min)}
    };

    return (
        <DayPickerInput
            inputProps={{className: 'form-input c-hand', readOnly: true, id: name, name}}
            dayPickerProps={dayPickerProps}
            formatDate={humanDate}
            parseDate={parseHumanDate}
            placeholder="Кликните на поле для выбора даты"
            onDayChange={day => onChange({target: {name, value: formatDate1(day)}})}
        />
    );
});

export default DateInput;