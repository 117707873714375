import React from "react";
import BaseRequestLabel from "../../shared/request/Label";
import {pluralize} from "../../../shared";
import {Col, Row} from "spectre-react";


const RequestLabel = ({request, onClick}) => {
    const daysNum = request.approvedDaysNum || request.daysNum;
    const daysNumString = `${daysNum} ${pluralize(daysNum, "дней", "день", "дня", "дней")}`;

    return (
        <BaseRequestLabel request={request} onClick={onClick}>
            <Row renderAs='span' gapless>
                <Col renderAs='span' all={1} md={2} sm={12}>
                    {daysNumString}
                </Col>
                <Col renderAs='span' all={11} md={10} sm={12} className="text-italic">
                    {request.comment}
                </Col>
            </Row>
        </BaseRequestLabel>
    )
};


export default RequestLabel;