import React, {useEffect} from 'react';
import {Redirect} from "react-router-dom";
import {useCurrentUserSetter, useCurrentUserValue} from "../../contexts/currentUser";
import useFetch from "../../hooks/useFetch";
import {Loading} from "spectre-react";


const SignInPage = ({location}) => {
    const currentUser = useCurrentUserValue();
    const setCurrentUser = useCurrentUserSetter();

    const [{isLoading, response, error}, doFetch] = useFetch(`/api/session`);

    useEffect(() => {
        if (!currentUser)
            doFetch();
    }, [currentUser, doFetch]);

    useEffect(() => {
        if (response && !response.location)
            setCurrentUser(response);
    }, [response, setCurrentUser]);

    useEffect(() => {
        // console.log(error.response);
        if (error && error.response && error.response.location) {
            const redirectURL = window.location.origin + (location.state?.from.pathname || '');
            const url = new URL(error.response.location); // SSO endpoint location
            url.searchParams.set('redirectURL', redirectURL);
            window.location.replace(url.href);
        }
    }, [error, location]);

    if (currentUser) {
        if (location.state)
            return <Redirect to={location.state.from}/>;
        else
            return <Redirect to="/"/>;
    }

    if (isLoading)
        return <Loading large/>;

    return null;
}


export default SignInPage;