import React from "react";


const UserSelect = ({options, value, setValue}) => {
    options = options.map(([userId, userName]) =>
        <option key={userId} value={userId}>{userName}</option>
    );

    const onChange = (ev) => setValue(ev.target.value);

    return (
        <select className="form-select select-sm text-bold select-text-uppercase" value={value} onChange={onChange}>
            <option value="">Сотрудник</option>
            {options}
        </select>
    )
};


export default UserSelect;