import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {REQUEST_KIND} from "../../shared";
import {Toast, Button, FormGroup, Input, Label} from "spectre-react";


const NewCTORequestForm = () => {
    useEffect(() => { document.title = "Регистрация отгула | Vacation"; }, []);

    const [daysNum, setDaysNum] = useState('1');
    const [comment, setComment] = useState('');

    const [submitState, _doSubmit] = useFetch("/api/requests/cto");

    const history = useHistory();
    useEffect(() => {
        if (submitState.response)
            history.push("/requests/cto");
    }, [submitState.response, history]);

    const doSubmit = (ev) => {
        ev.preventDefault();

        _doSubmit({
            method: 'post',
            data: {
                kind: REQUEST_KIND.CTO,
                daysNum,
                comment
            }
        });
    };

    return (
        <div className="container grid-sm">
            <form id="request-form">
                <FormGroup>
                    <Label form htmlFor="daysNum">Количество дней</Label>
                    <Input type="number" id="daysNum" name="daysNum" min="1" step="1"
                           value={daysNum} onChange={ev => setDaysNum(ev.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label form htmlFor="comment">Комментарий</Label>
                    <textarea className="form-input" id="comment" name="comment" required rows={3}
                              placeholder="Комментарий обязателен!"
                              value={comment} onChange={ev => setComment(ev.target.value)}
                    />
                </FormGroup>

                {submitState.error && <Toast error className="mt-6">{submitState.error.message}</Toast>}

                <FormGroup className="mt-6">
                    <Button type="submit" primary loading={submitState.isLoading} onClick={doSubmit}>
                        Отправить
                    </Button>
                </FormGroup>
            </form>
        </div>
    )
};

export default NewCTORequestForm;