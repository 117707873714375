import React, {useEffect} from "react";
import {useCurrentUserValue} from "../../../contexts/currentUser";
import useFetch from "../../../hooks/useFetch";
import {Loading, Toast} from "spectre-react";
import CTORequestsList from "./CTORequestsList";


const SubsCTORequestsList = () => {
    useEffect(() => { document.title = "Заявки подчиненных | Vacation" }, []);

    const currentUser = useCurrentUserValue();

    const [{isLoading, response: requests, error}, doFetch] = useFetch('/api/requests/cto/currentUserSubordinates');

    useEffect(doFetch, [doFetch]);

    // ----------

    if (error)
        return <Toast error>{error.message}</Toast>;

    if (isLoading || !requests)
        return <Loading large />;

    return <CTORequestsList requests={requests} currentUser={currentUser} onResponseSubmitted={doFetch} />;
};



export default SubsCTORequestsList;