import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import AppNavbar from "./components/AppNavbar";
import SignInPage from './pages/signInPage';
import SignOutPage from "./pages/signOutPage";
import RequestsPage from './pages/requestsPage';
import DiagramPage from './pages/diagramPage';
import EventsPage from './pages/eventsPage';
import CTOPage from "./pages/ctoPage";
import {CurrentUserProvider, useCurrentUserValue} from "./contexts/currentUser";

import './App.scss';


const AuthedRoute = (props) => {
    const currentUser = useCurrentUserValue();

    if (!currentUser)
        return <Redirect to={{
            pathname: "/sign_in",
            state: { from: props.location }
        }}/>;

    return <Route {...props}/>;
};


const App = () => {
    return (
        <CurrentUserProvider>
            <Router>
                <AppNavbar/>

                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/requests/absence"/>}/>
                    <Route exact path="/sign_in" component={SignInPage}/>

                    <AuthedRoute exact path="/sign_out" component={SignOutPage}/>

                    <AuthedRoute path="/requests/cto" component={CTOPage}/>
                    <AuthedRoute path="/requests/absence" component={RequestsPage}/>
                    <AuthedRoute path="/requests/new" component={RequestsPage}/>

                    <AuthedRoute exact path="/diagram" component={DiagramPage}/>
                    <AuthedRoute path="/events" component={EventsPage}/>

                    <Route component={() => <h1>404 Not Found</h1>}/>
                </Switch>
            </Router>
        </CurrentUserProvider>
    );
};

export default App;