const REQUEST_KIND = {
    VACATION_PAID: "VACATION_PAID",
    VACATION_UNPAID: "VACATION_UNPAID",
    DAYS_OFF: "DAYS_OFF",
    CTO: "CTO", // compensatory time off
};

const REQUEST_KIND_STR = {
    VACATION_PAID: "Оплачиваемый отпуск",
    VACATION_UNPAID: "Неоплачиваемый отпуск",
    DAYS_OFF: "Отгул",
    CTO: "Compensatory Time Off",
};

const RESPOND_ACTION = {
    APPROVE: "APPROVE",
    REJECT: "REJECT",
    CANCEL: "CANCEL",
};

const REQUEST_STATUS = {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    CANCELLED: "CANCELLED",
    PENDING: "PENDING",
};

const REQUEST_STATUS_STR = {
    APPROVED: "Одобрена",
    REJECTED: "Отклонена",
    CANCELLED: "Отменена сотрудником",
    PENDING: "На рассмотрении",
};

module.exports = {
    REQUEST_KIND,
    REQUEST_KIND_STR,
    RESPOND_ACTION,
    REQUEST_STATUS,
    REQUEST_STATUS_STR,
}