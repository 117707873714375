import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Loading} from "spectre-react";


const SignOutPage = ({location}) => {
    const [{isLoading, response}, doFetch] = useFetch(`/api/session`);
    useEffect(() => doFetch({method: 'delete'}), [doFetch]);

    if (response && response.location) {
        const redirectURL = window.location.origin + (location.state?.from.pathname || '');
        const url = new URL(response.location); // SSO endpoint location
        url.searchParams.set('redirectURL', redirectURL);
        window.location.replace(url.href);
    }

    if (isLoading)
        return <Loading large/>;

    return null;
};


export default SignOutPage;