const MONTHS_NOM = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"];
const MONTHS_GEN = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

function formatDateTime2(d) {
    return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + ", " +
           ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
}

function formatDate1(d) {
    return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
}

function date1to2s(str) {
    return str.slice(8) + '.' + str.slice(5,7) + '.' + str.slice(2,4);
}

function humanDate(date) {
    if (!date)
        return "";

    if (date instanceof Date)
        return [date.getDate(), MONTHS_GEN[date.getMonth()], date.getFullYear()].join(' ');

    return [date.slice(-2), MONTHS_GEN[parseInt(date.slice(5, 7), 10) - 1], date.slice(0, 4)].join(' ');
}

function parseHumanDate(dateStr) {
    if (!dateStr) return dateStr;
    const items = dateStr.split(' ');
    const date = items[0], monthGenitive = items[1], year = items[2];
    return new Date(parseInt(year, 10), MONTHS_GEN.indexOf(monthGenitive), parseInt(date, 10));
}

function humanPeriodString(startDate, endDate) {
    startDate = humanDate(startDate);
    endDate = humanDate(endDate);

    return startDate === endDate ? startDate : startDate + ' — ' + endDate;
}

module.exports = {
    formatDateTime2,
    formatDate1,
    date1to2s,
    humanDate,
    parseHumanDate,
    humanPeriodString,
    MONTHS_NOM
}