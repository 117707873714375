import React, {useLayoutEffect, useRef} from 'react'
import cx from 'classnames';
import VerticalLines from "./VerticalLines";

import DateHelper from '../helpers/DateHelper';
import {DATA_CONTAINER_WIDTH, ROW_HEIGHT} from '../helpers/Const';

const Item = ({item, left, width}) => {
    const className = cx("timeLine-data-task", item.class);
    const style = {backgroundColor: item.color, left, width, height: ROW_HEIGHT};
    return <div className={className} style={style}/>
};

const Row = ({row, index, nowPosition, dayWidth}) => {
    const style = {top: index * ROW_HEIGHT, height: ROW_HEIGHT};

    return (
        <div className="timeLine-data-row" style={style}>
            {row.items.map(item => {
                const position = DateHelper.dateToPixel(item.startDate, nowPosition, dayWidth);
                const width = DateHelper.dateToPixel(item.endDate, nowPosition, dayWidth) - position + dayWidth;
                return <Item key={item.startDate.valueOf()} left={position} width={width} item={item}/>;
            })}
        </div>
    );
};

const DataViewport = ({scrollLeft, nowPosition, dayWidth, rows, dragCallbacks, /* next props are for vertical lines */ showLines, currentDay, numVisibleDays, mode}) => {
    const wrapperRef = useRef();

    useLayoutEffect(() => {
        if (wrapperRef.current)
            wrapperRef.current.scrollLeft = scrollLeft;
    });

    const height = rows.length * ROW_HEIGHT;
    const wrapperStyle = {height: height, width: DATA_CONTAINER_WIDTH, maxWidth: DATA_CONTAINER_WIDTH};

    return (
        <div ref={wrapperRef} className="timeLine-data-viewPort" {...dragCallbacks}>
            <div className="timeLine-data-container" style={wrapperStyle}>
                {rows.map((row, index) => <Row key={row.name} row={row} index={index} nowPosition={nowPosition} dayWidth={dayWidth}/>)}
                {showLines && <VerticalLines dayWidth={dayWidth} nowPosition={nowPosition} currentDay={currentDay} numVisibleDays={numVisibleDays} mode={mode}/>}
            </div>
        </div>
    ) 
};

// DataViewport.propTypes = {
//     rows: propTypes.arrayOf(propTypes.shape({
//         name: propTypes.string.isRequired,
//         items: propTypes.arrayOf(propTypes.shape({
//             startDate: propTypes.instanceOf(Date).isRequired,
//             endDate: propTypes.instanceOf(Date).isRequired,
//             color: propTypes.string, // for events
//             class: propTypes.string // for vacations
//         }))
//     })),
// };

export default DataViewport;