export function groupBy(array, keyFunc, returnMap = false) {
    const map = new Map();

    for (const item of array) {
        const key = keyFunc(item);
        const group = map.get(key);

        if (group)
            group.push(item);
        else
            map.set(key, [item]);
    }

    return returnMap ? map : Array.from(map.entries());
}