import React, {useEffect, useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import EventForm from "./EventForm";
import useFetch from "../../hooks/useFetch";
import {formatDate1} from '../../shared';


function getDefaultEvent() {
    const date = formatDate1(new Date());

    return {
        name: '',
        startDate: date,
        endDate: date,
        color: '#000000'
    };
}


const NewEventPage = () => {
    const [event, setEvent] = useState(getDefaultEvent);

    const onChange = useCallback(ev => {
        const {name, value} = ev.target;
        setEvent(event => ({...event, [name]: value}));
    }, [setEvent]);

    // ------------

    const [submitState, doSubmit] = useFetch("/api/events");

    const history = useHistory();

    useEffect(() => {
        if (submitState.response)
            history.push("/events");
    }, [submitState.response, history]);

    const postEvent = useCallback(event => doSubmit({method: 'post', data: event}), [doSubmit]);

    // ------------

    return <EventForm title="Новое событие"
                      event={event}
                      onChange={onChange}
                      doSubmit={postEvent}
                      submitState={submitState}
    />
};

export default NewEventPage;