import React from "react";
import {CancelForm, RespondForm} from "./absenceRequestForms";
import {formatDateTime2, REQUEST_STATUS} from "../../../shared";
import {buildResponseItemTitle, getResponseIcon, pendingIcon} from '../../shared/request/helpers';
import {Timeline} from "spectre-react";
import RequestTimelineItem from "../../shared/request/TimelineItem";
import {AbsenceRequestsResponsesPT} from "../../propTypes";


const AbsenceRequestTimelineItem = ({iconColor, iconName, isFinal, createdAt, title, comment, children}) => {
    return (
        <RequestTimelineItem iconColor={iconColor} iconName={iconName} isFinal={isFinal} createdAt={createdAt}>
            <div>{title}</div>
            {comment && <div>Комментарий: <span className="text-italic">{comment}</span></div>}
            {children}
        </RequestTimelineItem>
    )
}


const RequestCreatedAt = ({request, isFinal, currentUser}) => {
    const createdAt = formatDateTime2(new Date(request.createdAt));

    if (request.authorId === currentUser._id) {
        const title = 'Вы создали заявку.';

        return (
            <AbsenceRequestTimelineItem createdAt={createdAt} isFinal={isFinal} title={title} comment={request.comment}>
                {request.status === REQUEST_STATUS.PENDING && <CancelForm request={request} />}
            </AbsenceRequestTimelineItem>
        );
    } else {
        const title = `${request.authorName} создал(а) заявку.`;

        return (
            <AbsenceRequestTimelineItem createdAt={createdAt} isFinal={isFinal} title={title} comment={request.comment}/>
        );
    }
};


const RequestResponse = ({response, currentUser, isFinal}) => {
    const {iconColor, iconName} = getResponseIcon(response.action);
    const createdAt = formatDateTime2(new Date(response.createdAt));
    const title = buildResponseItemTitle(response, response.authorId === currentUser._id);

    return (
        <AbsenceRequestTimelineItem iconColor={iconColor} iconName={iconName} isFinal={isFinal}
                                    createdAt={createdAt}
                                    title={title} comment={response.comment}
        />
    );
};


const RequestNextReview = ({request, currentUser}) => {
    if (!request.nextReviewerId)
        return null;

    const {iconColor, iconName} = pendingIcon;

    if (request.nextReviewerId === currentUser._id) {
        return (
            <AbsenceRequestTimelineItem iconColor={iconColor} iconName={iconName} createdAt="Сейчас" title="Вы:">
                <RespondForm request={request} />
            </AbsenceRequestTimelineItem>
        );
    } else {
        const createdAt = "В течение нескольких дней";
        const title = `${request.nextReviewerName}: ?`;

        return (
            <AbsenceRequestTimelineItem iconColor={iconColor} iconName={iconName} createdAt={createdAt} title={title}/>
        );
    }
}


const AbsenceRequestTimeline = ({request, responses, currentUser}) => {
    const isNotPending = request.status !== REQUEST_STATUS.PENDING;
    const responsesCount = responses.length;

    const items = [
        <RequestCreatedAt key={request.createdAt} request={request} isFinal={isNotPending && responsesCount === 0} currentUser={currentUser} />,
        ...responses.map((response, index) =>
            <RequestResponse key={response.createdAt} response={response} currentUser={currentUser}
                             isFinal={isNotPending && (index === responsesCount - 1)}
            />
        ),
        <RequestNextReview key={new Date()} request={request} currentUser={currentUser} />
    ];

    return (
        <Timeline>
            {items}
        </Timeline>
    );
};


AbsenceRequestTimeline.propTypes = {
    responses: AbsenceRequestsResponsesPT
}


export default AbsenceRequestTimeline;