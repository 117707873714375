import React from "react";
import {SCROLL_BACKWARD, SCROLL_FORWARD} from "../helpers/Const";
import {Button, Icon} from "spectre-react";


const Control = React.memo(({width, dispatch}) => {
    const scrollForward = () => dispatch({type: 'ADVANCE', payload: SCROLL_BACKWARD});
    const scrollBackward = () => dispatch({type: 'ADVANCE', payload: SCROLL_FORWARD});
    
    return (
        <div className="timeLine-control" style={{width}}>
            <Button link onClick={scrollForward}>
                <Icon icon="arrow-left"/> Назад
            </Button>
            <Button link onClick={scrollBackward}>
                Вперед <Icon icon="arrow-right"/>
            </Button>
        </div>
    );
});


export default Control;