import React from "react";
import ResponseSubmittedContext from "../../../contexts/responseSubmitted";
import RequestsGroup from "../../shared/RequestsGroup";
import Request from "../../shared/request";
import {groupBy} from "../shared";
import {REQUEST_STATUS, pluralize} from '../../../shared';
import RequestLabel from "./RequestLabel";
import RequestTimeline from "../shared/absenceRequestTimeline";
import {MyRequestsAbsenceRequestsPT} from "../../propTypes";



function filterRequests(requests, settings) {
    const {requestKinds, onlyPending} = settings;

    if (Object.values(requestKinds).some(value => value))
        requests = requests.filter(request => requestKinds[request.kind]);

    if (onlyPending)
        requests = requests.filter(request => request.status === REQUEST_STATUS.PENDING);

    return requests;
}


function buildGroups(requests, settings) {
    const groups = settings.groupByYear
        ? groupBy(requests, (request) => request.employmentYear)
            .map(([year, yearRequests]) => {
                const totalDays = yearRequests
                    .filter(request => request.status === REQUEST_STATUS.APPROVED)
                    .reduce((acc, item) => acc + item.daysCount, 0);
                const pluralWord = pluralize(totalDays, "дней", "день", "дня", "дней");
                const zeroedYear = ('0' + year).slice(-2);
                const title =  `${zeroedYear}-й год работы (всего ${totalDays} ${pluralWord})`;
                return [title, yearRequests];
            })
        : groupBy(requests, (request) => request.calendarYear.toString())

    return groups.sort((a, b) => b[0].localeCompare(a[0]));
}


const AbsenceRequestsList = ({requests, settings, onResponseSubmitted}) => {
    requests = filterRequests(requests, settings);
    const groups = buildGroups(requests, settings);

    return (
        <ResponseSubmittedContext.Provider value={onResponseSubmitted}>
            {groups.map(([title, requests]) =>
                <RequestsGroup key={title} title={title} open>
                    {requests.map(request =>
                        <Request key={request._id}
                                 requestType='absence'
                                 request={request}
                                 labelComponent={RequestLabel}
                                 timelineComponent={RequestTimeline}
                        />
                    )}
                </RequestsGroup>
            )}
        </ResponseSubmittedContext.Provider>
    )
}


AbsenceRequestsList.propTypes = { requests: MyRequestsAbsenceRequestsPT };


export default AbsenceRequestsList;