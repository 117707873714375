import {useState, useEffect, useCallback} from 'react';
import axios from 'axios';

axios.interceptors.response.use(
    function(response) { return response; },
    function(error) {
        if (error.response && error.response.data) {
            if (error.response.data.reason)
                error.message = error.response.data.reason;

            error.response = error.response.data;
        }

        return Promise.reject(error);
    }
);


const useFetch = (url) => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState({});

    const doFetch = useCallback((options = {}) => {
        setOptions(options);
        setIsLoading(true);
        setError(null);
    }, []);

    useEffect(() => {
        if (!isLoading) return;

        let skipGetResponseAfterDestroy = false;
        
        axios(/* baseUrl + */ url, options)
            .then(res => {
                if (!skipGetResponseAfterDestroy) {
                    setResponse(res.data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                if (!skipGetResponseAfterDestroy) {
                    setError(error);
                    setIsLoading(false);
                }
            });
            
        return () => {
            skipGetResponseAfterDestroy = true;
        }
    }, [isLoading, url, options]);

    return [{isLoading, response, error}, doFetch];
};


export default useFetch;