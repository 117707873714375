import React from "react";
import ResponseSubmittedContext from "../../../contexts/responseSubmitted";
import ReviewsRequiredList from "../../shared/ReviewsRequiredList";
import Request from "../../shared/request";
import RequestLabel from "./RequestLabel";
import RequestTimeline from "../shared/CTORequestTimeline";
import RequestsGroup from "../../shared/RequestsGroup";
import {SubordinatesRequestsCTORequestsPT} from "../../propTypes";


function groupByAuthorName(requests) {
    const groups = {};

    for (const request of requests) {
        if (!(request.authorName in groups))
            groups[request.authorName] = [request];
        else
            groups[request.authorName].push(request);
    }

    const entries = Object.entries(groups);
    entries.sort((a, b) => a[0].localeCompare(b[0]));

    return entries;
}


const RequestsList = ({requests, currentUser, onResponseSubmitted}) => {
    const reviewableRequests = requests.filter(({nextReviewerId}) => nextReviewerId === currentUser._id);

    const groups = groupByAuthorName(requests);

    return (
        <div className="requests-list">
            {reviewableRequests.length > 0 &&
                <ResponseSubmittedContext.Provider value={onResponseSubmitted}>
                    <ReviewsRequiredList count={reviewableRequests.length}>
                        {reviewableRequests.map(request =>
                            <Request key={request._id}
                                 requestType='cto'
                                 request={request}
                                 labelComponent={RequestLabel}
                                 timelineComponent={RequestTimeline}
                            />
                        )}
                    </ReviewsRequiredList>
                </ResponseSubmittedContext.Provider>
            }

            {groups.map(
                ([title, requests]) =>
                    <RequestsGroup key={title} title={title} requests={requests} open={false}>
                        {requests.map(request =>
                            <Request key={request._id}
                                requestType='cto'
                                request={request}
                                labelComponent={RequestLabel}
                                timelineComponent={RequestTimeline}
                            />
                        )}
                    </RequestsGroup>
            )}
        </div>
    )
};


RequestsList.propTypes = { requests: SubordinatesRequestsCTORequestsPT };


export default RequestsList;