import {RESPOND_ACTION} from "../../../shared";

const approveIcon = {iconColor: 'success', iconName: 'check'};
const rejectIcon = {iconColor: 'error', iconName: 'cross'};
const cancelIcon = {iconColor: 'primary', iconName: 'stop'};
export const pendingIcon = {iconColor: 'warning', iconName: 'more-horiz'};



export function getResponseIcon(action) {
    switch (action) {
        case RESPOND_ACTION.APPROVE: return approveIcon;
        case RESPOND_ACTION.REJECT: return rejectIcon;
        case RESPOND_ACTION.CANCEL: return cancelIcon;
        default: throw new Error("responseIcon() default case");
    }
}


export function buildResponseItemTitle(response, isCurrentUserAuthor) {
    switch (response.action) {
        case RESPOND_ACTION.APPROVE:
            return isCurrentUserAuthor
                ? "Вы одобрили заявку."
                : `${response.authorName} одобрил(а) заявку.`;

        case RESPOND_ACTION.REJECT:
            return isCurrentUserAuthor
                ? "Вы отклонили заявку."
                : `${response.authorName} отклонил(а) заявку.`;

        case RESPOND_ACTION.CANCEL:
            return isCurrentUserAuthor
                ? 'Вы отменили заявку.'
                : `${response.authorName} отменил(а) заявку.`;

        default:
            throw new Error("buildActionTitle(): Unknown action type");
    }
}