import {useState} from "react";
import {Table} from "spectre-react";
import UserSelect from "./UserSelect";
import {AllRequestsCTORequestsPT} from "../../propTypes";
import {formatDateTime2, pluralize, REQUEST_STATUS, REQUEST_STATUS_STR} from "../../../shared";


const STATUS_COLOR = {
    PENDING: 'warning',
    APPROVED: 'success',
    REJECTED: 'error'
};


const TableRow = ({request}) => {
    const statusString = request.status !== REQUEST_STATUS.PENDING
        ? REQUEST_STATUS_STR[request.status]
        : `${request.nextReviewerName}: ?`;

    const statusClassName = `text-${STATUS_COLOR[request.status]}`;

    return (
        <tr>
            <td>{request.authorName}</td>
            <td>{formatDateTime2(new Date(request.createdAt))}</td>
            <td>{request.daysNum} {pluralize(request.daysNum, "дней", "день", "дня", "дней")}</td>
            <td className={statusClassName}>{statusString}</td>
        </tr>
    );
};


function getUserSelectOptions(requests) {
    const users = {};

    for (const request of requests)
        if (!(request.authorId in users))
            users[request.authorId] = request.authorName;

    return Object.entries(users).sort((a, b) => a[1].localeCompare(b[1]));
}


const CTORequestsTable = ({requests}) => {
    const [selectedUserId, selectUserId] = useState("");

    const options = getUserSelectOptions(requests);

    if (selectedUserId)
        requests = requests.filter(request => request.authorId === selectedUserId);

    const rows = requests.map(request => <TableRow key={request._id} request={request}/>);

    return (
        <Table hover narrow id="cto-requests-table" className="font-light">
            <thead>
            <tr>
                <th className="text-uppercase">
                    <UserSelect options={options} setValue={selectUserId} value={selectedUserId}/>
                </th>
                <th className="text-uppercase">Создано</th>
                <th className="text-uppercase">Кол-во дней</th>
                <th className="text-uppercase">Статус</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    )
};


CTORequestsTable.propTypes = { requests: AllRequestsCTORequestsPT };


export default CTORequestsTable;