// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function regex(str) {
    return new RegExp(escapeRegexCharacters(str), 'i');
}

module.exports = {
    regex
}