import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {useCurrentUserValue} from "../../../contexts/currentUser";
import useFetch from "../../../hooks/useFetch";


const Request = ({requestType, request, labelComponent: LabelComponent, timelineComponent: TimelineComponent}) => {
    const [opened, setOpened] = useState(false);
    const toggle = useCallback(() => setOpened(value => !value), [setOpened]);


    const url = `/api/requests/${requestType}/${request.authorId}/${request._id}/responses`;
    const [{isLoading, response: responses}, doFetch] = useFetch(url);
    useEffect(() => {
        if (opened)
            doFetch();
    }, [opened, doFetch]);


    const currentUser = useCurrentUserValue();
    const className = cx(
        "request",
        {
            "loading": isLoading,
            "opened": opened,
        }
    );


    return (
        <div className={className}>
            <LabelComponent request={request} onClick={toggle}/>
            {opened && responses && <TimelineComponent request={request} responses={responses} currentUser={currentUser}/>}
        </div>
    );
};


Request.propTypes = {
    request: PropTypes.shape({
        authorId: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
    }).isRequired
};


export default Request;