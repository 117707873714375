import React from "react";
import KindFilter from "../shared/kindFilter";
import {Divider, Checkbox, FormGroup} from "spectre-react";


const StatusFilter = ({checked, onChange}) => {
    return (
        <FormGroup>
            <Checkbox name="onlyPending" checked={checked} onChange={onChange}>
                Только рассматриваемые
            </Checkbox>
        </FormGroup>
    )
}

const GroupByOption = ({checked, onChange}) => {
    return (
        <FormGroup>
            <Checkbox name="groupByYear" checked={checked} onChange={onChange}>
                Группировать по годам работы
            </Checkbox>
        </FormGroup>
    )
}

const Settings = ({settings, onChange}) => {
    const {groupByYear, requestKinds, onlyPending} = settings;

    return (
        <div>
            <KindFilter requestKinds={requestKinds} onChange={onChange}/>
            <Divider />
            <StatusFilter checked={onlyPending} onChange={onChange}/>
            <Divider />
            <GroupByOption checked={groupByYear} onChange={onChange}/>
        </div>
    )
}

export default Settings;