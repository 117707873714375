import React from "react";
import {ROW_HEIGHT} from "../helpers/Const";

const TaskList = React.memo(({rows, width}) => {
    return (
        <div className="timeLine-side" style={{width}}>
            <div className="timeLine-side-rows-container" style={{height: rows.length * ROW_HEIGHT}}>
                {rows.map((row, i) => (
                    <div key={row.name} className="timeLine-side-row" style={{top: i * ROW_HEIGHT, height: ROW_HEIGHT}}>
                        {row.name}
                    </div>
                ))}
            </div>
        </div>
    );
})

export default TaskList;