import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Redirect, Switch, NavLink} from 'react-router-dom';
import MyCTORequestsList from "./myCTORequestsList";
import SubsCTORequestsList from "./subsCTORequestsList";
import AllCTORequestsTable from "./allCTORequestsTable";


const URL_PATH = {
    MY: '/requests/cto/currentUser',
    SUBORDINATES: '/requests/cto/currentUserSubordinates',
    ALL: '/requests/cto/all',
    REQUEST: '/requests/cto/:userId/:requestId'
};


const Tabs = () => {
    return (
        <div className="text-center">
            <div className="tabs btn-group">
                <NavLink exact className="btn btn-sm" to={URL_PATH.MY}>Мои заявки</NavLink>
                <NavLink exact className="btn btn-sm" to={URL_PATH.SUBORDINATES}>Заявки подчиненных</NavLink>
                <NavLink exact className="btn btn-sm" to={URL_PATH.ALL}>Все заявки</NavLink>
            </div>
        </div>
    );
};


const CTORequestsPage = () => {
    useEffect(() => {
        document.title = "Регистрация отгулов | Vacation";
    }, []);

    return (
        <Router>
            <Tabs />

            <Switch>
                <Route exact path={URL_PATH.MY} component={MyCTORequestsList} />
                <Route exact path={URL_PATH.SUBORDINATES} component={SubsCTORequestsList} />
                <Route exact path={URL_PATH.ALL} component={AllCTORequestsTable} />

                <Redirect to={URL_PATH.MY}/>
            </Switch>
        </Router>
    )
}

export default CTORequestsPage;