import React from "react";
import {REQUEST_STATUS, REQUEST_STATUS_STR} from "../../../shared";
import {Icon, Tile, TileAction, TileContent, TileIcon} from "spectre-react";


const approveIcon = {iconColor: 'success', iconName: 'check'};
const rejectIcon = {iconColor: 'error', iconName: 'cross'};
const cancelIcon = {iconColor: 'primary', iconName: 'stop'};
const pendingIcon = {iconColor: 'warning', iconName: 'more-horiz'};


function getRequestIcon(requestStatus) {
    switch (requestStatus) {
        case REQUEST_STATUS.APPROVED: return approveIcon;
        case REQUEST_STATUS.REJECTED: return rejectIcon;
        case REQUEST_STATUS.CANCELLED: return cancelIcon;
        case REQUEST_STATUS.PENDING: return pendingIcon;
        default: throw new Error(`getRequestIcon got unknown argument: ${requestStatus}`);
    }
}


const RequestLabel = ({request, onClick, children}) => {
    const {iconColor, iconName} = getRequestIcon(request.status);

    const title = REQUEST_STATUS_STR[request.status];

    return (
        <Tile className="c-hand" onClick={onClick} title={title}>
            <TileIcon className={`icon-lg bg-${iconColor}`}>
                <Icon icon={iconName}/>
            </TileIcon>
            <TileContent>
                {children}
            </TileContent>
            <TileAction>
                <Icon icon="arrow-down"/>
            </TileAction>
        </Tile>
    )
};


export default RequestLabel;