import PropTypes from 'prop-types';
import {REQUEST_KIND, RESPOND_ACTION, REQUEST_STATUS} from "../shared/enums";


// -----------


const RequestResponseFields = {
    createdAt: PropTypes.string.isRequired,
    action: PropTypes.oneOf(Object.keys(RESPOND_ACTION)),
    authorId: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    comment: PropTypes.string,
}


const AbsenceRequestsResponsesPT = PropTypes.arrayOf(PropTypes.shape(RequestResponseFields).isRequired).isRequired;


const CTORequestResponsesPT = PropTypes.arrayOf(PropTypes.shape({
    ...RequestResponseFields,
    daysNum: PropTypes.number.isRequired,
}).isRequired);


// -----------


const AbsenceRequestFields = {
    _id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(REQUEST_STATUS)).isRequired,
    createdAt: PropTypes.string.isRequired, // TODO: date
    kind: PropTypes.oneOf(Object.keys(REQUEST_KIND)).isRequired,
    startDate: PropTypes.string.isRequired, // TODO: date
    endDate: PropTypes.string.isRequired, // TODO: date

    daysCount: PropTypes.number.isRequired,
    authorId: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    authorFullName: PropTypes.string.isRequired, // TODO: remove?
    nextReviewerId: PropTypes.string,
    nextReviewerName: PropTypes.string,
    employmentYear: PropTypes.number.isRequired,
    calendarYear: PropTypes.number.isRequired,
};


const MyRequestsAbsenceRequestsPT = PropTypes.arrayOf(PropTypes.shape({
    ...AbsenceRequestFields,

    authorName: undefined,
    authorFullName: undefined,
}).isRequired).isRequired;


const SubordinatesRequestsAbsenceRequestsPT = PropTypes.arrayOf(PropTypes.shape({
    ...AbsenceRequestFields,

    employmentYear: undefined,
}).isRequired).isRequired;


const AllRequestsAbsenceRequestsPT = PropTypes.arrayOf(PropTypes.shape({
    ...AbsenceRequestFields,

    authorFullName: undefined,
    nextReviewerId: undefined,
    nextReviewerName: undefined,
    employmentYear: undefined,
    calendarYear: undefined,
    createdAt: undefined
}).isRequired).isRequired;


// -----------


const CTORequestFields = {
    _id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(REQUEST_STATUS)).isRequired,
    createdAt: PropTypes.string.isRequired, // TODO: date
    daysNum: PropTypes.number.isRequired,

    authorId: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    nextReviewerId: PropTypes.string,
    nextReviewerName: PropTypes.string,
};

const MyRequestsCTORequestsPT = PropTypes.arrayOf(PropTypes.shape(CTORequestFields).isRequired).isRequired;
const SubordinatesRequestsCTORequestsPT = MyRequestsCTORequestsPT;
const AllRequestsCTORequestsPT = MyRequestsCTORequestsPT;


// -----------


const EventsPT = PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    authorId: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired
}).isRequired).isRequired;


// -----------


export {
    MyRequestsCTORequestsPT,
    MyRequestsAbsenceRequestsPT,
    SubordinatesRequestsCTORequestsPT,
    SubordinatesRequestsAbsenceRequestsPT,
    AllRequestsCTORequestsPT,
    AllRequestsAbsenceRequestsPT,
    AbsenceRequestsResponsesPT,
    CTORequestResponsesPT,
    EventsPT
}