import React from "react";
import RequestRespondForm from "./RequestRespondForm";
import {Timeline} from "spectre-react";
import {formatDateTime2, REQUEST_STATUS, RESPOND_ACTION} from "../../../shared";
import {buildResponseItemTitle, getResponseIcon, pendingIcon} from "../../shared/request/helpers";
import RequestTimelineItem from "../../shared/request/TimelineItem";
import {CTORequestResponsesPT} from "../../propTypes";


const RequestCreatedAt = ({request, isFinal, currentUser}) => {
    const createdAt = formatDateTime2(new Date(request.createdAt));

    const title = request.authorId === currentUser._id
        ? "Вы создали заявку."
        : `${request.authorName} создал(а) заявку.`

    return (
        <RequestTimelineItem createdAt={createdAt} isFinal={isFinal}>
            <div>{title}</div>
            <div>Количество дней: {request.daysNum}.</div>
            <div>Комментарий: <span className="text-italic">{request.comment || "<пусто>"}</span></div>
        </RequestTimelineItem>
    );
};


const RequestResponse = ({response, currentUser, isFinal}) => {
    const {iconColor, iconName} = getResponseIcon(response.action);
    const createdAt = formatDateTime2(new Date(response.createdAt));

    return (
        <RequestTimelineItem iconColor={iconColor} iconName={iconName} createdAt={createdAt} isFinal={isFinal}>
            <div>{buildResponseItemTitle(response, response.authorId === currentUser._id)}</div>
            {response.action === RESPOND_ACTION.APPROVE && <div>Количество дней: {response.daysNum}.</div>}
            <div>Комментарий: <span className="text-italic">{response.comment || "<пусто>"}</span></div>
        </RequestTimelineItem>
    );
};


const RequestNextReview = ({request, responses, currentUser}) => {
    if (!request.nextReviewerId)
        return null;

    const {iconColor, iconName} = pendingIcon;

    if (request.nextReviewerId === currentUser._id) {
        const defaultDaysNum = responses.length === 0 ? request.daysNum : responses[responses.length - 1].daysNum;

        return (
            <RequestTimelineItem iconColor={iconColor} iconName={iconName} createdAt="Сейчас">
                <div>Вы:</div>
                <RequestRespondForm request={request} defaultDaysNum={defaultDaysNum} />
            </RequestTimelineItem>
        );
    } else {
        return (
            <RequestTimelineItem iconColor={iconColor} iconName={iconName} createdAt="В течение нескольких дней">
                <div>{request.nextReviewerName}: ?</div>
            </RequestTimelineItem>
        );
    }
}


const CTORequestTimeline = ({request, responses, currentUser}) => {
    const isNotPending = request.status !== REQUEST_STATUS.PENDING;
    const responsesCount = responses.length;

    const items = [
        <RequestCreatedAt key={request.createdAt} request={request} isFinal={isNotPending && responsesCount === 0} currentUser={currentUser} />,
        ...responses.map((response, index) =>
            <RequestResponse key={response.createdAt} response={response} currentUser={currentUser}
                             isFinal={isNotPending && (index === responsesCount - 1)}
            />
        ),
        <RequestNextReview key={new Date()} request={request} responses={responses} currentUser={currentUser} />
    ];

    return (
        <Timeline>
            {items}
        </Timeline>
    );
};


CTORequestTimeline.propTypes = {
    responses: CTORequestResponsesPT
}


export default CTORequestTimeline;