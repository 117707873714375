import React, {useEffect} from "react";
import useFetch from "../../../hooks/useFetch";
import {Loading, Toast} from "spectre-react";
import CTORequestsList from "./CTORequestsList";


const MyCTORequestsList = () => {
    useEffect(() => { document.title = "Мои заявки | Vacation" }, []);

    const [{isLoading, response: requests, error}, doFetch] = useFetch('/api/requests/cto/currentUser');
    useEffect(doFetch, [doFetch]);

    if (error)
        return <Toast error>{error.message}</Toast>;

    if (isLoading || !requests)
        return <Loading large />;

    return <CTORequestsList requests={requests} />;
};


export default MyCTORequestsList;