import React from "react";
import KindFilter from "../shared/kindFilter";
import {MONTHS_NOM} from "../../../shared";
import {Divider, Button, Checkbox, Col, FormGroup, Icon, Row} from "spectre-react";


const MONTHS = Array.from({length: 12}, (_, i) => i + 1);


const Settings = ({years, settings, onChange}) => {
    const {requestKinds, year, month, onlyApproved} = settings;

    return (
        <div>
            <FormGroup>
                <Row>
                    <Col all={6}>
                        <select className="form-select" id="year" name="year" value={year} onChange={onChange}>
                            {years.map(year => <option key={year} value={year}>{year}</option>)}
                        </select>
                    </Col>
                    <Col all={6}>
                        <select className="form-select text-capitalize" id="month" name="month" value={month} onChange={onChange}>
                            {MONTHS.map(month => <option key={month} value={('0' + month).slice(-2)}>{MONTHS_NOM[month - 1]}</option>)}
                        </select>
                    </Col>
                </Row>
            </FormGroup>

            <Divider />

            <KindFilter requestKinds={requestKinds} onChange={onChange}/>

            <Divider />

            <FormGroup>
                <Checkbox name="onlyApproved" checked={onlyApproved} onChange={onChange}>
                    Только одобренные
                </Checkbox>
            </FormGroup>

            <Divider />

            <Button primary small href="/api/requests/absence/all.xlsx">
                <Icon icon="download"/> Скачать XLSX
            </Button>
        </div>
    )
}


export default Settings;