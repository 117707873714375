import {useEffect} from "react";
import useFetch from "../../../hooks/useFetch";
import CTORequestsTable from "./CTORequestsTable";
import {Loading, Toast} from "spectre-react";


const AllCTORequestsTable = () => {
    useEffect(() => { document.title = "Все заявки | Vacation" }, []);

    const [{isLoading, response: requests, error}, doFetch] = useFetch('/api/requests/cto/all');
    useEffect(doFetch, [doFetch]);

    if (error)
        return <Toast error>{error.message}</Toast>;

    if (isLoading || !requests)
        return <Loading large />;

    return <CTORequestsTable requests={requests} />;
};


export default AllCTORequestsTable;