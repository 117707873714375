import {TimelineItem, TimelineLeft, TimelineIcon, TimelineContent, Icon} from "spectre-react";
import PropTypes from "prop-types";


const RequestTimelineItem = ({iconColor, iconName, isFinal, createdAt, children}) => {
    console.log(iconColor, iconName, isFinal, createdAt, children);

    return (
        <TimelineItem final={isFinal}>
            <TimelineLeft>
                <TimelineIcon bg={iconColor}>
                    {iconName && <Icon icon={iconName} />}
                </TimelineIcon>
            </TimelineLeft>

            <TimelineContent>
                <div className="text-gray">{createdAt}</div>
                {children}
            </TimelineContent>
        </TimelineItem>
    )
};


RequestTimelineItem.propTypes = {
    iconColor: PropTypes.string,
    iconName: PropTypes.string,
    isFinal: PropTypes.bool,
    createdAt: PropTypes.string,
    children: PropTypes.node
}


export default RequestTimelineItem;