import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useCurrentUserValue} from "../../contexts/currentUser";
import useFetch from "../../hooks/useFetch";
import DateInput from "../../components/DateInput";
import {pluralize, REQUEST_KIND, REQUEST_KIND_STR} from "../../shared";
import {Toast, Button, Col, FormGroup, Label, Row} from "spectre-react";


const CTODaysAvailableMessage = () => {
    const currentUser = useCurrentUserValue();

    const url = `/api/requests/absence/${currentUser._id}/ctoDaysAvailable`;
    const [{isLoading, response, error}, doFetch] = useFetch(url);

    useEffect(doFetch, [doFetch]);

    if (isLoading)
        return <p className="loading loading-lg"/>;

    if (error || !response)
        return null;

    const daysNum = response.ctoDaysAvailable;

    return (
        <Toast className="mt-4 mb-2">
            Доступно отгулов: {daysNum} {pluralize(daysNum, 'дней', 'день', 'дня', 'дней')}
        </Toast>
    );
};


const NewAbsenceRequestForm = () => {
    useEffect(() => { document.title = "Подать заявку | Vacation" }, []);

    // --------

    const [kind, setKind] = useState(REQUEST_KIND.VACATION_PAID);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [comment, setComment] = useState('');

    // --------

    const [submitState, _doSubmit] = useFetch("/api/requests/absence");
    const history = useHistory();
    useEffect(() => {
        if (submitState.response)
            history.push("/requests/absence");
    }, [submitState.response, history]);

    // --------

    const [minStartDateFetchState, doFetchMinStartDate] = useFetch("/api/requests/absence/minStartDate");
    useEffect(doFetchMinStartDate, [doFetchMinStartDate]);

    // --------

    const doSubmit = (ev) => {
        ev.preventDefault();

        _doSubmit({
            method: 'post',
            data: { kind, startDate, endDate, comment }
        });
    };

    const minDate = minStartDateFetchState.response?.minStartDate;

    return (
        <div className="container grid-sm">
            <form id="request-form">
                <FormGroup>
                    <Label form htmlFor="kind">Вид заявки</Label>
                    <select className="form-select" id="kind" name="kind" value={kind} onChange={ev => setKind(ev.target.value)}>
                        <option value={REQUEST_KIND.VACATION_PAID}>{REQUEST_KIND_STR.VACATION_PAID}</option>
                        <option value={REQUEST_KIND.VACATION_UNPAID}>{REQUEST_KIND_STR.VACATION_UNPAID}</option>
                        <option value={REQUEST_KIND.DAYS_OFF}>{REQUEST_KIND_STR.DAYS_OFF}</option>
                    </select>
                </FormGroup>

                {kind === REQUEST_KIND.DAYS_OFF && <CTODaysAvailableMessage/>}

                <FormGroup>
                    <Row>
                        <Col md={12} all={6}>
                            <Label form htmlFor="startDate">Начальная дата</Label>
                            <DateInput name="startDate" value={startDate} onChange={ev => setStartDate(ev.target.value)} min={minDate}/>
                        </Col>
                        <Col md={12} all={6}>
                            <Label form htmlFor="startDate">Конечная дата (включ.)</Label>
                            <DateInput name="endDate" value={endDate} onChange={ev => setEndDate(ev.target.value)} min={minDate}/>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Label form htmlFor="comment">Комментарий</Label>
                    <textarea className="form-input" id="comment" name="comment" rows={3} value={comment} onChange={ev => setComment(ev.target.value)}/>
                </FormGroup>

                {submitState.error && <div className="toast toast-error mt-6">{submitState.error.message}</div>}

                <FormGroup className="mt-6">
                    <Button type="submit" primary loading={submitState.isLoading} onClick={doSubmit}>
                        Отправить
                    </Button>
                </FormGroup>
            </form>
        </div>
    )
};

export default NewAbsenceRequestForm;