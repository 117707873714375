const {regex} = require('./regex');
const {REQUEST_KIND, REQUEST_KIND_STR, RESPOND_ACTION, REQUEST_STATUS, REQUEST_STATUS_STR} = require('./enums');
const {formatDateTime2, formatDate1, date1to2s, humanDate, parseHumanDate, humanPeriodString, MONTHS_NOM} = require('./dates');


function isMobile() {
    return (/Mobi|Android/i.test(navigator.userAgent));
}


// https://github.com/kulakowka/pluralize-ru
function pluralize(i, str0, str1, str2, str5) {
    if (i === 0)
        return str0;
    else if (i % 10 === 1 && i % 100 !== 11)
        return str1;
    else if (i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 10 || i % 100 >= 20))
        return str2;
    else
        return str5;
}


module.exports = {
    regex,
    isMobile,
    pluralize,

    humanDate,
    humanPeriodString,
    parseHumanDate,
    formatDateTime2,
    formatDate1,
    date1to2s,
    MONTHS_NOM,

    REQUEST_KIND,
    REQUEST_KIND_STR,
    RESPOND_ACTION,
    REQUEST_STATUS,
    REQUEST_STATUS_STR,
};