import React from "react";
import {BrowserRouter as Router, Route, Redirect, Switch, NavLink} from 'react-router-dom';
import NewAbsenceRequestForm from "./NewAbsenceRequestForm";
import NewCTORequestForm from "./NewCTORequestForm";
import MyAbsenceRequestsList from "./myAbsenceRequestsList";
import SubsAbsenceRequestsList from "./subsAbsenceRequestsList";
import AllAbsenceRequestsList from "./allAbsenceRequestsTable";


const URL_PATH = {
    MY: '/requests/absence/currentUser',
    SUBORDINATES: '/requests/absence/currentUserSubordinates',
    ALL: '/requests/absence/all',
    NEW_ABSENCE: '/requests/new/absence',
    NEW_CTO: '/requests/new/cto'
};


const Tabs = () => {
    return (
        <header id="absence-requests-header" className="text-center">
            <div className="tabs btn-group">
                <NavLink exact className="btn btn-sm" to={URL_PATH.NEW_ABSENCE}>Подать заявку</NavLink>
                <NavLink exact className="btn btn-sm" to={URL_PATH.NEW_CTO}>Регистрация дней на отгул</NavLink>
            </div>

            <div className="tabs btn-group">
                <NavLink exact className="btn btn-sm" to={URL_PATH.MY}>Мои заявки</NavLink>
                <NavLink exact className="btn btn-sm" to={URL_PATH.SUBORDINATES}>Заявки подчиненных</NavLink>
                <NavLink exact className="btn btn-sm" to={URL_PATH.ALL}>Все заявки</NavLink>
            </div>
        </header>
    );
}


const RequestsPage = () => {
    return (
        <Router>
            <Tabs />

            <Switch>
                <Route exact path={URL_PATH.MY} component={MyAbsenceRequestsList} />
                <Route exact path={URL_PATH.SUBORDINATES} component={SubsAbsenceRequestsList} />
                <Route exact path={URL_PATH.ALL} component={AllAbsenceRequestsList} />

                <Route exact path={URL_PATH.NEW_ABSENCE} component={NewAbsenceRequestForm} />
                <Route exact path={URL_PATH.NEW_CTO} component={NewCTORequestForm} />

                <Redirect to={URL_PATH.MY}/>
            </Switch>
        </Router>
    )
}

export default RequestsPage;