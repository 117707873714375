import React, {useReducer, useState, useCallback, useEffect} from "react";
import {useCurrentUserValue} from "../../contexts/currentUser";
import useFetch from "../../hooks/useFetch";
import Timeline from "./timeline/Timeline";
import UserSelect from "./UserSelect";
import ScaleSwitcher, {SCALE} from "./ScaleSwitcher";
import VerticalLinesSwitcher from "./VerticalLinesSwitcher";
import reducer from './reducer';
import {Col, Row, Loading, Toast} from "spectre-react";


const stateInit = (currentUser) => ({
    requests: null,
    events: null,
    users: null,
    selectedUsers: [],
    selectedRequests: null, // filtered by selectedUsers when it's not an empty array, otherwise is equal to state.requests
    currentRequests: null,
    currentEvents: null,
    currentUser,
});


const DiagramPage = () => {
    useEffect(() => {document.title = "Диаграмма | Vacation"}, []);    

    const currentUser = useCurrentUserValue();
    const [state, dispatch] = useReducer(reducer, currentUser, stateInit);
    const [scale, setScale] = useState(SCALE.MONTH);
    const [showLines, setShowLines] = useState(true);

    // -------------

    const [{isLoading, response, error}, doFetch] = useFetch('/api/diagram');

    useEffect(doFetch, [doFetch]);

    useEffect(() => {
        if (response)
            dispatch({type: 'RECEIVE_DATA', payload: response});
    }, [response]);

    // -------------

    const onHorizonChange = useCallback(payload => dispatch({type: 'HORIZON_CHANGED', payload}), []);

    if (isLoading || !state.currentRequests || !state.currentEvents)
        return <Loading large />;

    if (error)
        return <Toast error>{error.message}</Toast>;

    return (
        <React.Fragment>
            <Row>
                <Col all={4} sm={12} className="mb-4">
                    <UserSelect availableUsers={state.users} selectedUsers={state.selectedUsers} dispatch={dispatch}/>
                </Col>
                <Col all={4} sm={12} className="mb-4">
                    <VerticalLinesSwitcher checked={showLines} setChecked={setShowLines}/>
                </Col>
                <Col all={4} sm={12} className="mb-4">
                    <ScaleSwitcher scale={scale} setScale={setScale}/>
                </Col>
            </Row>

            <div id="timeline-legend" className="mb-4">
                <div className="bg-success-l"/> и <div className="bg-warning-l"/> — Отгулы,&nbsp;
                <div className="bg-success"/> и <div className="bg-warning"/> — Отпуска
            </div>

            <div id="timeline-wrapper">
                <Timeline
                    scale={scale}
                    showLines={showLines}
                    requests={state.currentRequests}
                    events={state.currentEvents}
                    onHorizonChange={onHorizonChange}
                />
            </div>
        </React.Fragment>
    )
};


export default DiagramPage;