import React, {useContext, useEffect, useCallback, useState} from "react";
import ResponseSubmittedContext from "../../../contexts/responseSubmitted";
import useFetch from "../../../hooks/useFetch";
import {RESPOND_ACTION} from "../../../shared";
import {Button, FormGroup, Input, Label} from "spectre-react";


const useResponseApi = ({request, action, successMessage}) => {
    const url = `/api/requests/cto/${request.authorId}/${request._id}/responses`;
    const [fetchState, doFetch] = useFetch(url);

    const submitCallback = useContext(ResponseSubmittedContext);
    useEffect(() => {
        if (fetchState.response) {
            alert(successMessage);
            submitCallback();
        }

        if (fetchState.error)
            alert(`Ошибка: ${fetchState.error.message}`);
    }, [fetchState.response, fetchState.error, submitCallback]);

    const doSubmitResponse = useCallback((data) => {
        doFetch({ method: 'post', data: {...data, action} });
    }, [doFetch, action]);

    return [fetchState, doSubmitResponse];
};


const RequestRespondForm = ({request, defaultDaysNum}) => {
    const [approveFetchState, _doApprove] = useResponseApi({
        request,
        action: RESPOND_ACTION.APPROVE,
        successMessage: "Заявка одобрена."
    });

    const [rejectFetchState, _doReject] = useResponseApi({
        request,
        action: RESPOND_ACTION.REJECT,
        successMessage: "Заявка отклонена."
    });

    const [daysNum, setDaysNum] = useState(defaultDaysNum.toString());
    const [comment, setComment] = useState('');

    const doApprove = () => _doApprove({comment, daysNum});
    const doReject = () => _doReject({comment, daysNum});

    const isLoading = approveFetchState.isLoading || rejectFetchState.isLoading;

    return (
        <form className="mt-6">
            <FormGroup>
                <Label form htmlFor="daysNum">Количество дней:</Label>
                <Input type="number" id="daysNum" value={daysNum} onChange={ev => setDaysNum(ev.target.value)}/>
            </FormGroup>

            <FormGroup>
                <Label form htmlFor="comment">Комментарий:</Label>
                <textarea name="comment" id="comment" className="form-input"
                          placeholder="Если вы отклоняете заявку или изменяете количество дней, то необходимо указать комментарий.&#10;В случае одобрения — по желанию."
                          value={comment} onChange={ev => setComment(ev.target.value)}
                />
            </FormGroup>

            <FormGroup className="mt-6">
                <Button success className="mr-2" isLoading={isLoading} onClick={doApprove}>
                    Одобрить
                </Button>
                или
                <Button error className="ml-2" isLoading={isLoading} onClick={doReject}>
                    Отклонить
                </Button>
            </FormGroup>
        </form>
    )
};


export default RequestRespondForm;