import {REQUEST_STATUS, REQUEST_KIND} from "../../shared";


function getRequestColorClass(requestKind, requestStatus) {
    return requestKind === REQUEST_KIND.DAYS_OFF
        ? (requestStatus === REQUEST_STATUS.APPROVED ? "bg-success-l" : "bg-warning-l")
        : (requestStatus === REQUEST_STATUS.APPROVED ? "bg-success" : "bg-warning")
}


function isItemInsideHorizon({startDate, endDate}, start, end) {
    return (start < startDate && startDate < end) || (start < endDate && endDate < end);
}


function transformDiagramRow({userId, userName, requests}) {
    return {
        userId,
        name: userName,
        items: requests.map(request => ({
            startDate: new Date(request.startDate),
            endDate: new Date(request.endDate),
            class: getRequestColorClass(request.kind, request.status),
        }))
    }
}

function transformDiagramSection(rowsByUser) {
    return Object.keys(rowsByUser)
        .map(userId => transformDiagramRow(rowsByUser[userId]))
        .sort((a, b) => a.name.localeCompare(b.name));
}


function transformEvents(events) {
    return events.map(({name, startDate, endDate, color}) => ({
        name,
        items: [{
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            color
        }]
    }));
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'RECEIVE_DATA': {
            const requests = {
                user: [transformDiagramRow(action.payload.requests.user)],
                subordinates: transformDiagramSection(action.payload.requests.subordinates),
                other: transformDiagramSection(action.payload.requests.other),
            }

            const users = [
                ...requests.user.map(({userId, name}) => ({_id: userId, name})),
                ...requests.subordinates.map(({userId, name}) => ({_id: userId, name})),
                ...requests.other.map(({userId, name}) => ({_id: userId, name})),
            ].sort((a, b) => a.name.localeCompare(b.name));

            const events = transformEvents(action.payload.events);

            return {
                ...state,
                users,
                requests,
                selectedRequests: requests,
                currentRequests: requests,
                events,
                currentEvents: events
            };
        }

        case 'SET_USERS': {
            const selectedUsers = action.payload;

            if (!state.requests)
                return {...state, selectedUsers};

            if (selectedUsers.length === 0)
                return {...state, selectedUsers, selectedRequests: state.requests, currentRequests: state.requests};

            const selectedRequests = {
                user: state.requests.user,
                subordinates: state.requests.subordinates.filter(({userId}) => !!selectedUsers.find(({_id}) => _id === userId)),
                other: state.requests.other.filter(({userId}) => !!selectedUsers.find(({_id}) => _id === userId))
            };

            return {...state, selectedUsers, selectedRequests, currentRequests: selectedRequests};
        }

        case 'HORIZON_CHANGED': {
            const {start, end} = action.payload;

            const currentEvents = state.events.filter(event => isItemInsideHorizon(event.items[0], start, end));

            const currentRequests = { /* user: [], subordinates: [], other: [] */ };
            for (const key in state.requests)
                currentRequests[key] = state.selectedRequests[key].map(row => ({
                    ...row, items: row.items.filter(item => isItemInsideHorizon(item, start, end))
                }));

            return {...state, currentRequests, currentEvents};
        }

        default: throw new Error('diagram page reducer unknown event');
    }
};


export default reducer;