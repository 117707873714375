import React from "react";
import BaseRequestLabel from "../../shared/request/Label";
import {humanPeriodString, pluralize, REQUEST_KIND_STR} from "../../../shared";
import {Col, Label, Row} from "spectre-react";


const RequestLabel = ({request, onClick}) => {
    const {startDate, endDate, daysCount} = request;
    const datesString = humanPeriodString(startDate, endDate);
    const daysCountString = `${daysCount} ${pluralize(daysCount, "дней", "день", "дня", "дней")}`;

    return (
        <BaseRequestLabel request={request} onClick={onClick}>
            <Row gapless>
                <Col all={3} lg={5}>
                    {request.authorName}
                </Col>
                <Col all={5} lg={12}>
                    {datesString} ({daysCountString})
                </Col>
                <Col all={4} lg={7} className="text-right text-left-lg">
                    <Label rounded>
                        {REQUEST_KIND_STR[request.kind]}
                    </Label>
                </Col>
            </Row>
        </BaseRequestLabel>
    )
};


export default RequestLabel;