import React, {useEffect, useCallback} from "react";
import {Link} from 'react-router-dom';
import useFetch from "../../hooks/useFetch";
import {useCurrentUserValue} from "../../contexts/currentUser";
import {humanPeriodString} from '../../shared';
import {EventsPT} from "../propTypes";
import {Button, Loading, Toast} from "spectre-react";


const TableRow = ({event, onDelete}) => {
    const [deleteState, _doDelete] = useFetch(`/api/events/${event.authorId}/${event._id}`);

    const doDelete = useCallback(() => {
        if (window.confirm("Точно удалить?"))
            _doDelete({method: 'delete', params: {rev: event._rev}});

    }, [event._rev, _doDelete]);

    useEffect(() => {
        if (deleteState.response)
            onDelete();

        if (deleteState.error)
            alert(deleteState.error.message);
    }, [deleteState.response, deleteState.error, onDelete]);


    const currentUser = useCurrentUserValue();
    const showActions = event.authorId === currentUser._id;

    return (
        <tr>
            <td style={{color: event.color}}><span>{event.name}</span></td>
            <td><span>{humanPeriodString(event.startDate, event.endDate)}</span></td>
            <td><span>{event.authorName}</span></td>
            <td>{showActions &&
                <Link className="btn btn-sm btn-primary btn-block"
                      to={`/events/${event.authorId}/${event._id}/edit`}>
                    Редактировать
                </Link>}
            </td>
            <td>
                {showActions &&
                    <Button small primary block loading={deleteState.isLoading} onClick={doDelete}>
                        Удалить
                    </Button>
                }
            </td>
        </tr>
    )
};


const Table = ({events, onDelete}) => {
    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th className="text-uppercase">Название</th>
                    <th className="text-uppercase">Период</th>
                    <th className="text-uppercase">Автор</th>
                    <th/>
                    <th/>
                </tr>
            </thead>

            <tbody>
            {events.map(event => <TableRow key={event._id} event={event} onDelete={onDelete}/>)}
            </tbody>
        </table>
    )
};


const EventsTable = () => {
    const [{isLoading, response: events, error}, doFetch] = useFetch("/api/events");
    useEffect(doFetch, [doFetch]);

    if (error)
        return <Toast error>{error.message}</Toast>;

    if (isLoading || !events)
        return <Loading large />;

    return <Table events={events} onDelete={doFetch} />
};


Table.propTypes = { events: EventsPT };


export default EventsTable;