import React from "react";
import {Toast, Button, FormGroup, Input, Label} from "spectre-react";


const EventForm = ({title, event, onChange, doSubmit, submitState}) => {
    const {name, startDate, endDate, color} = event;

    const doSubmitEvent = (ev) => {
        ev.preventDefault();
        doSubmit(event);
    };

    return (
        <form className="container grid-sm">
            <fieldset>
                <legend>{title}</legend>

                <FormGroup>
                    <Label form htmlFor="name">Название</Label>
                    <Input type="text" id="name" name="name" value={name} onChange={onChange} required/>
                </FormGroup>
                <FormGroup>
                    <Label form htmlFor="startDate">Начало</Label>
                    <Input type="date" id="startDate" name="startDate" value={startDate} onChange={onChange} required/>
                </FormGroup>
                <FormGroup>
                    <Label form htmlFor="endDate">Конец</Label>
                    <Input type="date" id="endDate" name="endDate" value={endDate} onChange={onChange} required/>
                </FormGroup>
                <FormGroup>
                    <Label form htmlFor="color">Цвет</Label>
                    <Input type="color" id="color" name="color" value={color} onChange={onChange} required/>
                </FormGroup>

                {submitState.error &&
                    <FormGroup>
                        <Toast error className="mb-2">
                            {submitState.error.message}
                        </Toast>
                    </FormGroup>
                }
            </fieldset>

            <FormGroup className="mt-6">
                <Button type="submit" primary loading={submitState.isLoading} onClick={doSubmitEvent}>
                    Отправить
                </Button>
            </FormGroup>
        </form>
    );
};


export default EventForm;