import React, {useEffect, useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import EventForm from "./EventForm";
import useFetch from "../../hooks/useFetch";
import {Loading, Toast} from "spectre-react";


const EditEventPage = ({match}) => {
    const [event, setEvent] = useState();

    const onChange = useCallback(ev => {
        const {name, value} = ev.target;
        setEvent(event => ({...event, [name]: value}));
    }, [setEvent]);

    // ------------

    const {authorId, eventId} = match.params;

    const [fetchState, doFetch] = useFetch(`/api/events/${authorId}/${eventId}`);

    useEffect(doFetch, [doFetch]);

    useEffect(() => setEvent(fetchState.response), [fetchState.response]);

    // ------------

    const [submitState, doSubmit] = useFetch(`/api/events/${authorId}/${eventId}`);

    const history = useHistory();

    const postEvent = useCallback(event => doSubmit({method: 'post', data: event}), [doSubmit]);

    useEffect(() => {
        if (submitState.response)
            history.push("/events");
    }, [submitState.response, history]);

    // ------------

    return (
        <div>
            {fetchState.isLoading &&
                <Loading large />
            }

            {fetchState.error &&
                <Toast error>{fetchState.error.message}</Toast>
            }

            {!fetchState.isLoading && event &&
                <EventForm title="Редактирование события"
                           event={event}
                           onChange={onChange}
                           doSubmit={postEvent}
                           submitState={submitState}
                />
            }
        </div>
    )
};

export default EditEventPage;