import React from 'react';
import elementResizeDetectorMaker from "element-resize-detector";
import propTypes from 'prop-types';

import {DATA_CONTAINER_WIDTH, VIEW_MODE_MONTH, VIEW_MODE_YEAR} from '../helpers/Const';
import DateHelper from '../helpers/DateHelper';

class Header extends React.Component {
    static propTypes = {
        dayWidth: propTypes.number,
        nowPosition: propTypes.number,
        currentDay: propTypes.number,
        numVisibleDays: propTypes.number,
        mode: propTypes.string,
        scrollLeft: propTypes.number,
        dragCallbacks: propTypes.objectOf(propTypes.func),
        dispatch: propTypes.func,
    };

    YEAR_ROWS = {
        top: {incType: 'year', fmtStr: 'YYYY'},
        middle: {incType: 'month', fmtStr: 'MMMM'},
        bottom: {incType: 'week', fmtStr: 'WW'}
    };

    MONTH_ROWS = {
        top: {incType: 'month', fmtStr: 'MMMM YYYY'},
        middle: {incType: 'day', fmtStr: 'dd'},
        bottom: {incType: 'day', fmtStr: 'D'}
    };

    erd = elementResizeDetectorMaker();
    ref = React.createRef();

    componentDidMount() {
        this.erd.listenTo({strategy: 'scroll'}, this.ref.current, (element) => {
            // const width = element.offsetWidth;
            // const height = element.offsetHeight;
            // const width = getComputedStyle(element).width;
            const width = element.getBoundingClientRect().width;
            this.props.dispatch({type: 'CHANGE_VIEWPORT_SIZE', payload: width});
        });
    }

    componentWillUnmount() {
        this.erd.uninstall(this.ref.current);
    }

    renderHeaderItem(left, width, label, key) {
        return <div key={key} className="timeLine-header-item" style={{left, width}}>{label}</div>;
    }

    renderHeaderRow(rowProps) {
        const {incType, fmtStr} = rowProps;
        const {currentDay, numVisibleDays, nowPosition, dayWidth} = this.props;

        let date = new Date();
        date.setDate(date.getDate() + currentDay - 1);
        date = DateHelper.getStartDate(date, incType);

        let end = new Date();
        end.setDate(end.getDate() + currentDay + numVisibleDays);

        let label = DateHelper.format(date, fmtStr),
            left = nowPosition + DateHelper.daysDiff(date) * dayWidth,
            increment = DateHelper.getIncrement(date, incType);

        const items = [];

        do {
            items.push(this.renderHeaderItem(left, increment * dayWidth, label, date.valueOf()));
            date.setDate(date.getDate() + increment);
            label = DateHelper.format(date, fmtStr);
            left += increment * dayWidth;
            increment = DateHelper.getIncrement(date, incType);
        } while (date <= end);

        return items;
    }

    renderHeaderRows(rows) {
        return (
            <div className="timeLine-header-container" style={{width: DATA_CONTAINER_WIDTH, maxWidth: DATA_CONTAINER_WIDTH}}>
                <div className="timeLine-header-row header-top">
                    {this.renderHeaderRow(rows.top)}
                </div>
                <div className="timeLine-header-row header-middle">
                    {this.renderHeaderRow(rows.middle)}
                </div>
                <div className="timeLine-header-row header-bottom">
                    {this.renderHeaderRow(rows.bottom)}
                </div>
            </div>
        )
    }

    renderHeader() {
        switch (this.props.mode) {
            case VIEW_MODE_YEAR: return this.renderHeaderRows(this.YEAR_ROWS);
            case VIEW_MODE_MONTH: return this.renderHeaderRows(this.MONTH_ROWS);
            default: return null;
        }
    }

    componentDidUpdate() {
        if (this.ref.current)
            this.ref.current.scrollLeft = this.props.scrollLeft;
    }

    render() {
        return (
            <div ref={this.ref} className="timeLine-header-viewPort" {...this.props.dragCallbacks}>
                {this.renderHeader()}
            </div>
        );
    }
}

export default Header;