import React, {useState, useCallback} from "react";
import propTypes from "prop-types";
import useModal from "../../hooks/useModal";
import {pluralize, regex} from "../../shared";
import {Button, Input, Label} from "spectre-react";


const UsersList = ({users, onClick}) => {
    if (users.length === 0)
        return null;

    return (
        <ul className="menu my-2">
            {users.map(user =>
                <li key={user._id} className="menu-item">
                    <a onClick={onClick} data-user-id={user._id}>
                        <span className="btn btn-clear float-right"/>
                        <span>{user.name}</span>
                    </a>
                </li>
            )}
        </ul>
    )
}

const Modal = ({availableUsers, selectedUsers: defaultValue, dispatch, closeModal}) => {
    const [searchText, setSearchText] = useState("");
    const [selectedUsers, setSelectedUsers] = useState(defaultValue);

    const selectUser = useCallback((ev) => {
        const userId = ev.currentTarget.dataset.userId;
        const selectedUser = availableUsers.find(user => user._id === userId);
        setSelectedUsers(selectedUsers => [...selectedUsers, selectedUser].sort((a, b) => a.name.localeCompare(b.name)));
        setSearchText("");
    }, [availableUsers]);

    const deselectUser = useCallback((ev) => {
        const userId = ev.currentTarget.dataset.userId;
        const deselectedUser = availableUsers.find(user => user._id === userId);
        setSelectedUsers(selectedUsers => selectedUsers.filter(user => user !== deselectedUser));
    }, [availableUsers]);

    const doSubmit = () => {
        dispatch({type: 'SET_USERS', payload: selectedUsers});
        closeModal();
    }

    let searchUsers = availableUsers.filter(user => selectedUsers.indexOf(user) === -1);
    if (searchText) {
        const re = regex(searchText);
        searchUsers = searchUsers.filter(user => re.test(user.name));
    }

    return (
        <div className="modal active" id="users-modal">
            <span className="modal-overlay" onClick={closeModal}/>
            <div className="modal-container">
                <div className="modal-header">
                    <span className="btn btn-clear float-right" onClick={closeModal}/>
                    <div className="modal-title h5">Сотрудники</div>
                </div>
                <div className="modal-body columns">
                    <div className="column col-6 col-sm-12">
                        <div>Выбранные ({selectedUsers.length}):</div>
                        <UsersList users={selectedUsers} onClick={deselectUser} />
                    </div>
                    <div className="column col-6 col-sm-12">
                        <Label form htmlFor="search">Доступные для выбора ({availableUsers.length - selectedUsers.length}):</Label>
                        <Input type="search" id="search" className="mt-2" onChange={ev => setSearchText(ev.target.value)} value={searchText}/>
                        <UsersList users={searchUsers} onClick={selectUser} />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button primary className="mr-2" onClick={doSubmit}>
                        Сохранить
                    </Button>

                    <Button link onClick={closeModal}>
                        Закрыть
                    </Button>
                </div>
            </div>
        </div>
    )
}

const UserSelect = React.memo((props) => {
    const [isOpened, openModal, closeModal] = useModal();
    const n = props.selectedUsers.length;
    const title = n > 0
        ? `Выбран${n > 1 ? 'о' : ''} ${n} ${pluralize(n, '', 'сотрудник', 'сотрудника', 'сотрудников')}`
        : 'Выбрать сотрудников';

    return (
        <React.Fragment>
            <Button primary small onClick={openModal}>
                {title}
            </Button>

            {isOpened && <Modal {...props} closeModal={closeModal}/>}
        </React.Fragment>
    )
});

// -------


const UsersPropType = propTypes.arrayOf(propTypes.shape({
    _id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
}))


UsersList.propTypes = {
    users: propTypes.array,
    onClick: propTypes.func,
};

Modal.propTypes = {
    closeModal: propTypes.func,
    availableUsers: UsersPropType,
    selectedUsers: UsersPropType,
    dispatch: propTypes.func,
}

UserSelect.propTypes = {
    availableUsers: UsersPropType,
    selectedUsers: UsersPropType,
    dispatch: propTypes.func,
}

export default UserSelect;