import React from "react";
import KindFilter from "../shared/kindFilter";
import {Divider, Checkbox, FormGroup} from "spectre-react";


const StatusFilter = ({onlyApproved, onlyPending, onChange}) => {
    return (
        <FormGroup>
            <Checkbox name="onlyApproved" checked={onlyApproved} onChange={onChange}>
                Только одобренные
            </Checkbox>
            <Checkbox name="onlyPending" checked={onlyPending} onChange={onChange}>
                Только рассматриваемые
            </Checkbox>
        </FormGroup>
    )
}

const GroupByOption = ({checked, onChange}) => {
    return (
        <FormGroup>
            <Checkbox name="groupByUser" checked={checked} onChange={onChange}>
                Группировать по сотрудникам
            </Checkbox>
        </FormGroup>
    )
}

const Settings = ({settings, onChange}) => {
    const {requestKinds, onlyApproved, onlyPending, groupByUser} = settings;

    return (
        <div>
            <KindFilter requestKinds={requestKinds} onChange={onChange}/>
            <Divider />
            <StatusFilter onlyApproved={onlyApproved} onlyPending={onlyPending} onChange={onChange}/>
            <Divider />
            <GroupByOption checked={groupByUser} onChange={onChange}/>
        </div>
    )
}

export default Settings;