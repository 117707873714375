import React, {useContext, useState} from "react";

const CurrentUserValueContext = React.createContext();
const CurrentUserSetterContext = React.createContext();

function CurrentUserProvider({children}) {
    const [currentUser, setCurrentUser] = useState();

    return (
        <CurrentUserValueContext.Provider value={currentUser}>
            <CurrentUserSetterContext.Provider value={setCurrentUser}>
                {children}
            </CurrentUserSetterContext.Provider>
        </CurrentUserValueContext.Provider>
    )
}

function useCurrentUserValue() {
    return useContext(CurrentUserValueContext);
}

function useCurrentUserSetter() {
    return useContext(CurrentUserSetterContext)
}

export {CurrentUserProvider, useCurrentUserValue, useCurrentUserSetter};