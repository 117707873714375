import React from "react";
import cx from "classnames";
import {VIEW_MODE_MONTH, VIEW_MODE_YEAR} from "./timeline/helpers/Const";
import {Button} from "spectre-react";


export const SCALE = {
    MONTH: VIEW_MODE_MONTH,
    YEAR: VIEW_MODE_YEAR
};


const ScaleSwitcher = React.memo(({scale, setScale}) => {
    return (
        <div className="btn-group btn-group-block">
            <Button small active={scale === SCALE.MONTH} onClick={() => setScale(SCALE.MONTH)}>
                Месяц
            </Button>
            <Button small active={scale === SCALE.YEAR} onClick={() => setScale(SCALE.YEAR)}>
                Год
            </Button>
        </div>
    )
});


export default ScaleSwitcher;