export const DATA_CONTAINER_WIDTH = 5000;
export const SIDE_ROW_MIN_WIDTH = 108;
export const ROW_HEIGHT = 20;

export const VIEW_MODE_MONTH = "month";
export const VIEW_MODE_YEAR = "year";

export const DAY_WIDTH_YEAR_MODE = 4;
export const DAY_WIDTH_MONTH_MODE = 28;

export const SCROLL_FORWARD = 'forward';
export const SCROLL_BACKWARD = 'backward';

export const HORIZON = 800;