import React from 'react';
import {VIEW_MODE_YEAR} from '../helpers/Const';
import DateHelper from "../helpers/DateHelper";

// const Line = ({left}) => <div className="timeLine-grid" style={{left}}/>;

// const VerticalLines1 = ({dayWidth, nowPosition, currentDay, numVisibleDays, mode}) => {
//     const incType = mode === VIEW_MODE_YEAR ? 'week' : 'day';

//     let date = new Date();
//     date.setDate(date.getDate() + currentDay);
//     date = DateHelper.getStartDate(date, incType);

//     let end = new Date();
//     end.setDate(end.getDate() + currentDay + numVisibleDays);

//     let left = nowPosition + DateHelper.daysDiff(date) * dayWidth,
//         increment = DateHelper.getIncrement(date, incType);

//     const items = [];
    
//     do {
//         items.push(<Line key={date.valueOf()} left={left}/>);
//         date.setDate(date.getDate() + increment);
//         left += increment * dayWidth;
//     } while (date <= end)

//     return items;
// }

// -------

const Lines = React.memo(({colsNum, colWidth}) => {
    const lines = [];
    for (let i = 0; i < colsNum; ++i)
        lines.push(<div key={i} className="timeLine-col" style={{width: colWidth}}/>);
    return lines;
});

const VerticalLines = ({dayWidth, nowPosition, currentDay, numVisibleDays, mode}) => {
    const incType = mode === VIEW_MODE_YEAR ? 'week' : 'day';

    let date = new Date();
    date.setDate(date.getDate() + currentDay);
    date = DateHelper.getStartDate(date, incType);

    let left = nowPosition + DateHelper.daysDiff(date) * dayWidth,
        increment = DateHelper.getIncrement(date, incType);
    
    return (
        <div className="timeLine-cols" style={{left}}>
            <Lines colsNum={Math.trunc(numVisibleDays / increment) + 1} colWidth={increment * dayWidth}/>
        </div>
    );
}

export default VerticalLines;