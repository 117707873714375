import {useState, useCallback} from "react";


const useModal = () => {
    const [opened, setOpened] = useState(false);
    const [data, setData] = useState();

    const open = useCallback((data) => {
        setData(data);
        setOpened(true);
    }, [setOpened]);

    const close = useCallback(() => {
        setData(null);
        setOpened(false);
    }, [setOpened]);

    return [opened, open, close, data];
};


export default useModal;