import {useState, useCallback} from "react";


const useSettingsObject = (defaultSettings) => {
    const [settings, setSettings] = useState(defaultSettings);

    const onChange = useCallback((ev) => {
        const name = ev.target.name;
        const value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value;
        setSettings(settings => ({...settings, [name]: value}));
    }, [setSettings]);

    return [settings, onChange];
};


export default useSettingsObject;