import {useCallback, useRef} from 'react'

const useDrag = (dispatch) => {
    const isDragging = useRef(false);
    const position = useRef(false);

    const dragViewport = useCallback(delta => {
        dispatch({type: 'DRAG_VIEWPORT', payload: delta})
    }, [dispatch]);

    const onTouchStart = useCallback(ev => {
        isDragging.current = true;
        position.current = ev.touches[0].clientX;
    }, []);

    const onMouseDown = useCallback((ev) => {
        if (ev.button === 0) { // left-click
            isDragging.current = true;
            position.current = ev.clientX;
        }
    }, []);

    const onTouchMove = useCallback((ev) => {
        if (isDragging.current) {
            const delta = position.current - ev.touches[0].clientX;
            position.current = ev.touches[0].clientX;
            dragViewport(delta);
        }
    }, [dragViewport]);

    const onMouseMove = useCallback((ev) => {
        if (isDragging.current) {
            const delta = position.current - ev.clientX;
            position.current = ev.clientX;
            dragViewport(delta);
        }
    }, [dragViewport]);

    const stopDragging = useCallback(() => isDragging.current = false, []);

    return {
        onTouchStart, onMouseDown,
        onTouchMove, onMouseMove,
        onTouchEnd: stopDragging, onMouseUp: stopDragging,
        onTouchCancel: stopDragging, onMouseLeave: stopDragging
    };
};

export default useDrag;