import React from "react";
import {REQUEST_KIND, REQUEST_KIND_STR} from "../../../shared";
import {Checkbox, FormGroup} from "spectre-react";


const KindFilter = ({requestKinds, onChange}) => {
    const onChangeWrap = (ev) => {
        const {name, checked} = ev.target;
        const value = {...requestKinds, [name]: checked};
        onChange({target: {name: 'requestKinds', value}});
    }

    return (
        <FormGroup>
            <Checkbox
                name={REQUEST_KIND.VACATION_PAID}
                checked={requestKinds[REQUEST_KIND.VACATION_PAID]}
                onChange={onChangeWrap}
            >
                {REQUEST_KIND_STR.VACATION_PAID}
            </Checkbox>
            <Checkbox
                name={REQUEST_KIND.VACATION_UNPAID}
                checked={requestKinds[REQUEST_KIND.VACATION_UNPAID]}
                onChange={onChangeWrap}
            >
                {REQUEST_KIND_STR.VACATION_UNPAID}
            </Checkbox>
            <Checkbox
                name={REQUEST_KIND.DAYS_OFF}
                checked={requestKinds[REQUEST_KIND.DAYS_OFF]}
                onChange={onChangeWrap}
            >
                {REQUEST_KIND_STR.DAYS_OFF}
            </Checkbox>
        </FormGroup>
    )
}


export default KindFilter;