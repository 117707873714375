import React from "react";
import {humanDate, pluralize, REQUEST_STATUS, REQUEST_KIND_STR} from '../../../shared';
import {AllRequestsAbsenceRequestsPT} from "../../propTypes";
import {Table} from "spectre-react";


function filterRequests(requests, settings) {
    const {requestKinds, year, month, onlyApproved} = settings;

    requests = requests.filter(request => {
        const [reqYear, reqMonth, ] = request.startDate.split('-');
        return reqYear === year && reqMonth === month;
    })

    if (Object.values(requestKinds).some(value => value))
        requests = requests.filter(request => requestKinds[request.kind]);

    if (onlyApproved)
        requests = requests.filter(request => request.status === REQUEST_STATUS.APPROVED);

    return requests;
}


const TableRow = ({request}) => {
    const daysCount = request.daysCount
    const daysCountString = `${daysCount} ${pluralize(daysCount, 'дней', 'день', 'дня', 'дней')}`;

    return (
        <tr>
            <td>{request.authorName}</td>
            <td>{humanDate(request.startDate)}</td>
            <td>{humanDate(request.endDate)}</td>
            <td>{daysCountString}</td>
            <td>{REQUEST_KIND_STR[request.kind]}</td>
        </tr>
    )
}


const AbsenceRequestsTable = ({requests, settings}) => {
    requests = filterRequests(requests, settings);

    return (
        <Table hover narrow className="font-light">
            <thead>
                <tr>
                    <th className="text-uppercase">Сотрудник</th>
                    <th className="text-uppercase">Начало</th>
                    <th className="text-uppercase">Конец</th>
                    <th className="text-uppercase">Дней</th>
                    <th className="text-uppercase">Вид заявки</th>
                </tr>
            </thead>
            <tbody>
            {requests.map(request => <TableRow key={request._id} request={request} />)}
            </tbody>
        </Table>
    );
}


AbsenceRequestsTable.propTypes = { requests: AllRequestsAbsenceRequestsPT };


export default AbsenceRequestsTable;